import { useState, useEffect } from "react";
import { Check } from "lucide-react";
import { PushableButton } from "@/components/PushableButton";
import { useToast } from "@/components/ui/use-toast";
import { motion } from "framer-motion";
import { useUserStore } from "@/stores/useUserStore";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription
} from "@/components/ui/dialog";
import { cn } from "@/lib/utils";
import {
  createCreditsPaymentIntent,
  addCreditsAfterPayment
} from "@/utils/api";
import { getPlatform } from "@/utils/platform";
import { RevenueCatPurchases } from "@/components/RevenueCatPurchases";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAvailablePackages, PRODUCT_IDS } from "@/utils/purchases";
import { Capacitor } from "@capacitor/core";
import { getPath } from "@/utils/paths";

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

// Payment form component
const PaymentForm = ({
  onPaymentSuccess
}: {
  onPaymentSuccess: (paymentIntentId: string) => void;
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          payment_method_data: {
            billing_details: {} // This ensures 3D Secure works properly
          }
        }
      });

      if (error) {
        setErrorMessage(error.message);
        setIsProcessing(false);
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        onPaymentSuccess(paymentIntent.id);
      }
    } catch (e) {
      console.error("Error confirming payment:", e);
      setErrorMessage("An unexpected error occurred.");
      setIsProcessing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <PaymentElement />
      {errorMessage && (
        <div className="mt-4 text-red-600 text-sm">{errorMessage}</div>
      )}
      <button
        type="submit"
        disabled={!stripe || isProcessing}
        className={cn(
          "mt-6 w-full bg-rose-600 text-white py-2 px-4 rounded-md",
          "hover:bg-rose-700 disabled:opacity-50 disabled:cursor-not-allowed",
          "transition-all duration-200 ease-in-out"
        )}
      >
        {isProcessing ? "Processing..." : "Unlock Your 222 Credits"}
      </button>
    </form>
  );
};

// Payment form wrapper
const PaymentFormWrapper = ({
  onPaymentSuccess,
  onClose
}: {
  onPaymentSuccess: (paymentIntentId: string) => void;
  onClose: () => void;
}) => {
  const [clientSecret, setClientSecret] = useState<string>();

  useEffect(() => {
    createCreditsPaymentIntent()
      .then((data) => setClientSecret(data.clientSecret))
      .catch((err) => {
        console.error("Error creating payment intent:", err);
        onClose(); // Close modal on error
      });
  }, [onClose]);

  if (!clientSecret) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-rose-600" />
      </div>
    );
  }

  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: "stripe"
        }
      }}
    >
      <PaymentForm onPaymentSuccess={onPaymentSuccess} />
    </Elements>
  );
};

interface CreditsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreditsModal: React.FC<CreditsModalProps> = ({ isOpen, onClose }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const { updateCredits } = useUserStore();
  const { isNative } = getPlatform();
  const [packagePrice, setPackagePrice] = useState<string>("$19.99");
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);

  // Fetch price from RevenueCat for native devices
  useEffect(() => {
    if (isNative && Capacitor.isNativePlatform() && isOpen) {
      const fetchPackagePrice = async () => {
        try {
          setIsLoadingPrice(true);
          const availablePackages = await getAvailablePackages([
            PRODUCT_IDS.CREDITS_PACKAGE
          ]);

          if (
            Array.isArray(availablePackages) &&
            availablePackages.length > 0
          ) {
            const firstPackage = availablePackages[0];
            if (firstPackage && firstPackage.product) {
              // Use the localized price string from RevenueCat
              setPackagePrice(firstPackage.product.priceString);
            }
          }
        } catch (error) {
          console.error("Error fetching package price:", error);
          // Fallback to default price if there's an error
          setPackagePrice("$19.99");
        } finally {
          setIsLoadingPrice(false);
        }
      };

      fetchPackagePrice();
    }
  }, [isNative, isOpen]);

  // Mutation for adding credits after successful payment
  const addCreditsMutation = useMutation({
    mutationFn: async (paymentIntentId: string) => {
      const response = await addCreditsAfterPayment(paymentIntentId);
      if (!response) {
        throw new Error("Failed to add credits");
      }
      return response;
    },
    onSuccess: (data) => {
      updateCredits(data.credits);
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      toast({
        title: "Success!",
        description: "222 credits have been added to your account."
      });
      setShowPaymentModal(false);
      onClose();
    },
    onError: () => {
      toast({
        title: "Error",
        description:
          "Failed to add credits to your account. Please contact support.",
        variant: "destructive"
      });
    }
  });

  const handlePaymentSuccess = (paymentIntentId: string) => {
    addCreditsMutation.mutate(paymentIntentId);
  };

  const includedFeatures = [
    "222 Credits To Explore Your Readings",
    "Explore The Meaning Of Your Cards",
    "Ask any question",
    "Access Anywhere, Anytime"
  ];

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-xl p-0 rounded-2xl border border-primary-foreground/20">
        <div className="relative z-10 p-6">
          {/* Inner border similar to pricing section */}
          <div className="absolute inset-x-3 inset-y-3 border-2 border-primary-foreground/20 rounded-xl"></div>

          <div className="relative z-10">
            <div className="flex items-center justify-center mb-4">
              <motion.div
                animate={{
                  rotate: [0, 2, 0, -2, 0],
                  scale: [1, 1.05, 1],
                  y: [0, 10, 0]
                }}
                transition={{
                  rotate: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 15,
                    ease: "easeInOut"
                  },
                  scale: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 15,
                    ease: "easeInOut"
                  },
                  y: {
                    repeat: Infinity,
                    repeatType: "loop",
                    duration: 15,
                    ease: "easeInOut",
                    delay: 5
                  }
                }}
                className={cn(
                  "aspect-square bg-contain bg-center bg-no-repeat origin-center",
                  "w-20 h-20 md:w-28 md:h-28"
                )}
                style={{
                  backgroundImage: `url('${getPath(
                    "/gemstones/amethyst-2.png"
                  )}')`
                }}
              />
            </div>
            <h3
              className={cn(
                "text-primary-foreground relative z-10",
                "font-semibold mb-2 text-center",
                "text-2xl md:text-3xl md:leading-normal"
              )}
            >
              Continue Your Conversation
            </h3>
            <p className="text-center text-primary-foreground mt-4 mb-6 max-w-[400px] mx-auto font-rosarivo">
              With 222 Tarot Credits, dive deeper into your readings, uncover
              hidden meanings, and receive personalized guidance tailored to
              your unique journey.
            </p>

            {/* Feature list with centered text */}
            <div className="flex items-center justify-center">
              <ul
                role="list"
                className="mt-4 mb-6 space-y-3 text-primary-foreground"
              >
                {includedFeatures.map((feature, index) => (
                  <li key={index} className="flex gap-3 items-center">
                    <Check
                      aria-hidden="true"
                      className="h-6 w-5 flex-none text-rose-700"
                    />
                    <span className="font-rosarivo">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            {/* Price and Purchase with styling from pricing section */}
            <div className="flex flex-col items-center justify-center mt-5 mb-4 relative">
              {/* Background glow effect */}
              <div className="absolute inset-0 flex items-center justify-center blur">
                <motion.div
                  className={cn(
                    "-z-10 absolute",
                    "w-52 h-52",
                    "bg-yellow-600/50"
                  )}
                  initial={{ opacity: 0 }}
                  animate={{
                    opacity: [0.1, 0.2, 0.1],
                    scale: [0.7, 0.8, 0.7],
                    borderRadius: [
                      "30% 70% 70% 30% / 30% 30% 70% 70%",
                      "60% 40% 30% 70% / 60% 30% 70% 40%",
                      "30% 60% 70% 40% / 50% 60% 30% 60%",
                      "30% 70% 70% 30% / 30% 30% 70% 70%"
                    ]
                  }}
                  transition={{
                    duration: 16,
                    repeat: Infinity,
                    repeatType: "mirror",
                    ease: "easeInOut",
                    delay: 1.6,
                    opacity: {
                      repeat: 1,
                      duration: 8
                    }
                  }}
                />
              </div>
              <div className="flex flex-col items-center justify-center text-primary-foreground text-4xl font-semibold relative drop-shadow-xl text-center space-y-4 mt-4 mb-6 font-rosarivo">
                <span>
                  <span className="text-5xl relative">
                    <span className="relative z-10 text-rose-600">222</span>
                    <span className="absolute inset-0 text-rose-600/75 top-2 blur-lg">
                      222
                    </span>
                  </span>
                  <span className="text-4xl"> Credits</span>
                </span>
                <span className="text-3xl font-semibold tracking-tight text-primary-foreground block">
                  {isLoadingPrice ? "Loading..." : `For ${packagePrice}`}
                </span>
              </div>
            </div>

            {/* Purchase button */}
            <div className="mt-6 flex justify-center flex-col items-center">
              {isNative ? (
                <div className="w-full max-w-md">
                  <RevenueCatPurchases onPurchaseComplete={onClose} />
                </div>
              ) : (
                <>
                  <PushableButton
                    text="Get Your 222 Credits Now"
                    onClick={() => setShowPaymentModal(true)}
                    size="normal"
                    className="mx-auto mb-2"
                  />
                  <p className="text-xs text-primary-foreground/70 mt-2 text-center max-w-[250px]">
                    Secure payment via Stripe. Instant access to your credits.
                  </p>

                  <Dialog
                    open={showPaymentModal}
                    onOpenChange={setShowPaymentModal}
                  >
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Complete Your Purchase</DialogTitle>
                        <DialogDescription>
                          You're just moments away from unlocking 222 credits
                          and deeper tarot insights.
                        </DialogDescription>
                      </DialogHeader>
                      <PaymentFormWrapper
                        onPaymentSuccess={handlePaymentSuccess}
                        onClose={() => setShowPaymentModal(false)}
                      />
                    </DialogContent>
                  </Dialog>
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreditsModal;
