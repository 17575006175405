import { useState, useEffect } from "react";
import { Capacitor } from "@capacitor/core";
import { PurchasesPackage } from "@revenuecat/purchases-capacitor";
import {
  getAvailablePackages,
  purchasePackage,
  PRODUCT_IDS
} from "@/utils/purchases";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";
import { queryClient } from "@/utils/queryClient";
import { Loader } from "lucide-react";
import { PushableButton } from "./PushableButton";

interface RevenueCatPurchasesProps {
  onPurchaseComplete?: () => void;
}

// Helper function to extract credit amount from product ID
const getCreditAmountFromProductId = (productId: string): number => {
  // Map product IDs to credit amounts
  switch (productId) {
    case "com.youruniquetarot.app.credits222":
    case "credits_222_v1":
    case "credits_222_v2":
    case PRODUCT_IDS.CREDITS_PACKAGE:
      return 222;
    case "com.youruniquetarot.app.premium":
      return 222; // Initial credits for premium package
    default:
      // Handle custom or unknown products
      if (productId.includes("credits")) {
        // Extract number from product ID if it follows a pattern like "credits100"
        const match = productId.match(/credits(\d+)/i);
        if (match && match[1]) {
          return parseInt(match[1], 10);
        }
      }
      return 222; // Default fallback
  }
};

export function RevenueCatPurchases({
  onPurchaseComplete
}: RevenueCatPurchasesProps) {
  const [packages, setPackages] = useState<PurchasesPackage[]>([]);
  const [selectedPackage, setSelectedPackage] =
    useState<PurchasesPackage | null>(null);
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    const fetchPackages = async () => {
      try {
        setIsLoading(true);
        // Get available packages for credits
        const availablePackages = await getAvailablePackages([
          PRODUCT_IDS.CREDITS_PACKAGE
        ]);

        console.log("availablePackages", availablePackages);

        if (Array.isArray(availablePackages) && availablePackages.length > 0) {
          setPackages(availablePackages as PurchasesPackage[]);
          // Default to first package
          setSelectedPackage(availablePackages[0] as PurchasesPackage);
        }
      } catch (error) {
        console.error("Error fetching packages:", error);
        toast({
          title: "Error",
          description: "Failed to load available packages",
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchPackages();
  }, [toast]);

  const handlePurchase = async () => {
    if (!selectedPackage) return;

    try {
      setIsPurchasing(true);

      // Get the product ID and determine credit amount
      const productId = selectedPackage.product.identifier;
      const creditAmount = getCreditAmountFromProductId(productId);

      // Make the purchase
      const result = await purchasePackage(selectedPackage);
      console.log("Purchase result:", result);

      // Wait a moment for the backend to process the purchase
      setTimeout(() => {
        // Refresh user data to get updated credits
        queryClient.invalidateQueries({ queryKey: ["currentUser"] });

        toast({
          title: "Purchase Successful!",
          description: `${creditAmount} credits have been added to your account.`
        });

        // Call the onPurchaseComplete callback to close the modal
        if (onPurchaseComplete) {
          onPurchaseComplete();
        }
      }, 300);
    } catch (error: any) {
      console.error("Purchase error:", error);

      // Don't show error for user cancellation
      if (
        error.code !== "2" &&
        error.code !== "user_cancelled" &&
        error.message !== "Purchase was cancelled by user"
      ) {
        // Check if the purchase might have succeeded despite the error
        // This can happen if the RevenueCat purchase succeeds but backend validation fails
        if (
          error.message &&
          (error.message.includes("404") || error.message.includes("400"))
        ) {
          console.log(
            "Backend validation failed but purchase might have succeeded"
          );

          // Get the product ID and determine credit amount
          const productId = selectedPackage.product.identifier;
          const creditAmount = getCreditAmountFromProductId(productId);

          // Wait a moment and then refresh user data
          setTimeout(() => {
            // Refresh user data to get updated credits
            queryClient.invalidateQueries({ queryKey: ["currentUser"] });

            toast({
              title: "Purchase Likely Successful",
              description: `Your purchase was processed. ${creditAmount} credits should be added to your account shortly.`
            });

            // Call the onPurchaseComplete callback to close the modal
            if (onPurchaseComplete) {
              onPurchaseComplete();
            }
          }, 300);
        } else {
          // Show error for other issues
          toast({
            title: "Purchase Failed",
            description:
              error.message || "There was an error processing your purchase.",
            variant: "destructive"
          });
        }
      } else {
        console.log("Purchase was cancelled by user");
      }
    } finally {
      setIsPurchasing(false);
    }
  };

  if (!Capacitor.isNativePlatform()) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        <Loader className="h-8 w-8 animate-spin text-primary-foreground" />
      </div>
    );
  }

  if (packages.length === 0) {
    return (
      <div className="text-center p-4">
        <p className="text-primary-foreground-muted mb-4">
          No packages available at this time.
        </p>
        <Button onClick={() => setIsLoading(true)}>Refresh</Button>
      </div>
    );
  }

  // Get the product ID and credit amount for the button text
  const productId =
    selectedPackage?.product.identifier || PRODUCT_IDS.CREDITS_PACKAGE;
  const creditAmount = getCreditAmountFromProductId(productId);

  return (
    <div className="flex justify-center items-center">
      <PushableButton
        text={
          isPurchasing
            ? "Processing..."
            : `Get Your ${creditAmount} Credits Now`
        }
        onClick={handlePurchase}
        disabled={!selectedPackage || isPurchasing}
        size="normal"
        className="mx-auto"
      />
    </div>
  );
}
