import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import Login from "@/pages/login";
import Register from "./pages/register";
import DailyDraw from "./pages/DailyDraw";
import { QueryClientProvider } from "@tanstack/react-query";
import ProtectedRoute from "./components/ProtectedRoute";
import Deck from "./pages/Deck";
import ReadingDetail from "./pages/ReadingDetail";
import ReadingOverview from "./pages/ReadingOverview";
import Credits from "./pages/Credits";
import { Toaster } from "./components/ui/toaster";
import { useAuth } from "@/hooks/useAuth";
import Contact from "./pages/Contact";
import Guides from "./pages/Guides";
import Logout from "./pages/logout";
import Verify from "./pages/verify";
import { Textures } from "./components/Textures";
import posthog from "posthog-js";
import { HelmetProvider } from "react-helmet-async";
import { getPlatform } from "./utils/platform";
import CardDetail from "./pages/CardDetail";
import ResetOnboarding from "./pages/ResetOnboarding";
import { Capacitor } from "@capacitor/core";
import { initializePurchases } from "./utils/purchases";
import { queryClient } from "./utils/queryClient";
import { cn } from "./lib/utils";
import { getBaseUrl } from "./utils/paths";
import ModuleList from "./components/flashcards/ModuleList";
import FlashcardStudy from "./components/flashcards/FlashcardStudy";

posthog.init("phc_F1oC9TcflfHJNSHIklqMeq0B7A3czOpIDvmP9QkjRDx", {
  api_host: "https://eu.i.posthog.com",
  person_profiles: "identified_only"
});

function PageViewTracker() {
  const location = useLocation();

  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  return null;
}

function AppContent() {
  useAuth();

  const { isNative } = getPlatform();

  // Initialize StatusBar for native apps
  useEffect(() => {
    const setupCapacitorInit = async () => {
      if (Capacitor.isNativePlatform()) {
        try {
          // Initialize RevenueCat for in-app purchases
          await initializePurchases();
        } catch (e) {
          console.error("Error initializing purchases:", e);
        }
      }
    };

    setupCapacitorInit();
  }, []);

  return (
    <div
      className={cn(
        "bg-background font-rosarivo relative min-h-[calc(100svh)]",
        isNative
          ? "portrait:px-[var(--safe-area-left)] pb-[var(--safe-area-bottom)] pt-[var(--safe-area-top)]"
          : ""
      )}
      id="app-content"
    >
      <Textures type="paper-1" />

      <div className="relative">
        <div className="relative z-10">
          <Routes>
            {/* Root path now redirects to login within the basename context */}
            <Route path="/" element={<Navigate replace to="/login" />} />

            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-onboarding" element={<ResetOnboarding />} />
            <Route path="/debug" element={<ResetOnboarding />} />

            {/* Logged In Routes */}
            <Route
              path="/daily"
              element={
                <ProtectedRoute>
                  <DailyDraw />
                </ProtectedRoute>
              }
            />
            <Route
              path="/guides"
              element={
                <ProtectedRoute>
                  <Guides />
                </ProtectedRoute>
              }
            />
            <Route
              path="/deck"
              element={
                <ProtectedRoute>
                  <Deck />
                </ProtectedRoute>
              }
            />
            <Route
              path="/readings/:id"
              element={
                <ProtectedRoute>
                  <ReadingDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/readings"
              element={
                <ProtectedRoute>
                  <ReadingOverview />
                </ProtectedRoute>
              }
            />
            <Route
              path="/feedback"
              element={
                <ProtectedRoute>
                  <Contact />
                </ProtectedRoute>
              }
            />
            <Route
              path="/credits"
              element={
                <ProtectedRoute>
                  <Credits />
                </ProtectedRoute>
              }
            />
            <Route
              path="/deck/:id"
              element={
                <ProtectedRoute>
                  <CardDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/logout"
              element={
                <ProtectedRoute>
                  <Logout />
                </ProtectedRoute>
              }
            />
            {/* Verify Email */}
            <Route path="/verify/:token" element={<Verify />} />

            {/* Flashcard Learning System Routes */}
            <Route
              path="/learn"
              element={
                <ProtectedRoute>
                  <ModuleList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/learn/study/:moduleId?"
              element={
                <ProtectedRoute>
                  <FlashcardStudy />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Router basename={getBaseUrl()}>
          <PageViewTracker />
          <Toaster />
          <AppContent />
        </Router>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
