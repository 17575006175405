import { useEffect, useState } from "react";
import { getAssetPath } from "@/utils/paths";
import { cn } from "@/lib/utils";
import { getPlatform } from "@/utils/platform";

const preloadImage = (src: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve();
    img.onerror = reject;
    img.src = src;
  });
};

export const Textures = ({
  type,
  className
}: {
  type: "paper-1" | "paper-2";
  className?: string;
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { isNative } = getPlatform();

  // Choose texture based on platform and type
  const texturePath = isNative
    ? getAssetPath("/paper-texture-seamless.jpg")
    : getAssetPath("/paper-2.jpg");

  useEffect(() => {
    const loadTexture = async () => {
      try {
        await preloadImage(texturePath);
        setIsLoaded(true);
      } catch (error) {
        console.error("Failed to load texture:", error);
        // Still show the texture even if preload fails
        setIsLoaded(true);
      }
    };

    loadTexture();
  }, [type, texturePath]);

  if (type === "paper-1") {
    return (
      <div
        id="paper-texture"
        className={cn(
          "h-full w-full absolute inset-0 bg-background bg-center bg-repeat bg-blend-multiply bg-[length:300px_300px] transition-opacity duration-300",
          isLoaded ? "opacity-40" : "opacity-0",
          className
        )}
        style={{
          backgroundImage: `url('${getAssetPath(
            "/paper-texture-seamless.jpg"
          )}')`
        }}
      />
    );
  } else if (type === "paper-2") {
    return (
      <div
        id="fabric-texture"
        className={cn(
          "absolute inset-0 bg-center bg-repeat bg-[length:300px_300px] bg-blend-multiply transition-opacity duration-300 bg-background",
          isLoaded ? "opacity-30" : "opacity-0",
          className
        )}
        style={{ backgroundImage: `url('${getAssetPath("/paper-2.jpg")}')` }}
      />
    );
  }
};
