import { useState } from "react";
import Layout from "@/components/layout";
import CreditsModal from "@/components/CreditsModal";

const Credits = () => {
  const [showCreditsModal, setShowCreditsModal] = useState(true);

  return (
    <Layout>
      <div className="flex items-center justify-center min-h-[calc(100vh-76px)]">
        <CreditsModal
          isOpen={showCreditsModal}
          onClose={() => setShowCreditsModal(false)}
        />
      </div>
    </Layout>
  );
};

export default Credits;
