import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useEffect } from "react";
import { z } from "zod";
import AuthNavbar from "@/components/AuthNavbar";
import { useSearchParams } from "react-router-dom";
import { TarotFormField } from "@/components/TarotFormField";
import { Form } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { PushableButton } from "@/components/PushableButton";
import { guides } from "./Guides";
import { cn } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { registerUser } from "@/utils/api";
import { useUserStore } from "@/stores/useUserStore";
import { useMutation } from "@tanstack/react-query";
import { Textures } from "@/components/Textures";
import { getPlatform, storeAuthToken } from "@/utils/platform";
import Navbar from "@/components/navbar";
import { getPath } from "@/utils/paths";

const formSchema = z.object({
  name: z.string().min(1, "Name is required"),
  email: z.string().email("Invalid email address"),
  password: z.string().min(8, "Password must be at least 8 characters long"),
  preferredGuideId: z.number().min(1, "Please select a guide")
});

type FormData = z.infer<typeof formSchema>;

export default function RegisterPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();
  const { setUser } = useUserStore();
  const { isNative } = getPlatform();

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      email: "",
      password: "",
      preferredGuideId: 1
    }
  });

  useEffect(() => {
    // Pre-fill form fields from URL parameters
    const name = searchParams.get("name");
    const email = searchParams.get("email");

    if (name) form.setValue("name", name);
    if (email) form.setValue("email", email);
  }, [searchParams, form]);

  const mutation = useMutation({
    mutationFn: registerUser,
    onSuccess: async (data) => {
      // Use the new token storage function that handles both web and native platforms
      await storeAuthToken(data.token);
      setUser(data.user);
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });

      // Navigate to daily draw with a showOnboarding parameter
      navigate("/daily?showOnboarding=true", {
        state: {
          message: "Please check your email to verify your account."
        }
      });
    },
    onError: (error) => {
      console.error("Error:", error);
      // You might want to add error handling here
    }
  });

  const onSubmit = async (values: FormData) => {
    // Register user (initial credits handled on backend)
    mutation.mutate({
      ...values
    });
  };

  const handleGuideSelect = (guideId: number) => {
    form.setValue("preferredGuideId", guideId);
  };

  const selectedGuide = form.watch("preferredGuideId");

  return (
    <>
      {/* Always show AuthNavbar regardless of platform */}
      {isNative ? <Navbar /> : <AuthNavbar />}

      <div
        className={cn(
          "flex items-center justify-center",
          "min-h-[calc(100svh-76px-var(--safe-area-top))] mt-10 pb-10 lg:pb-24"
        )}
      >
        <div className="flex flex-1 justify-start content-start flex-wrap max-w-4xl relative px-4">
          {/* Remove floating card illustrations for mobile */}
          {!isNative && (
            <>
              <motion.img
                initial={{ opacity: 0, rotate: 10, y: 0, x: -50 }}
                animate={{
                  opacity: 1,
                  rotate: [0, 5, 0],
                  y: [0, 50, 0],
                  x: 0
                }}
                transition={{
                  duration: 0.5,
                  delay: 3.5,
                  rotate: {
                    duration: 10,
                    repeat: Infinity,
                    ease: "easeInOut"
                  },
                  y: {
                    duration: 20,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }
                }}
                src={getPath("/card-assets/pents-01.png")}
                className="w-32 absolute bottom-[5%] -right-24 hidden lg:block"
              />
              <motion.img
                initial={{ opacity: 0, rotate: 10, y: 0, x: -50 }}
                animate={{
                  opacity: 3,
                  rotate: [0, 5, 0],
                  y: [0, 50, 0],
                  x: 0
                }}
                transition={{
                  duration: 0.5,
                  delay: 2,
                  rotate: {
                    duration: 10,
                    repeat: Infinity,
                    ease: "easeInOut"
                  },
                  y: {
                    duration: 25,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }
                }}
                src={getPath("/card-assets/sword-01.png")}
                className="w-28 absolute -top-10 -right-20 hidden lg:block"
              />
              <motion.img
                initial={{ opacity: 0, rotate: -10, y: 0, x: 20 }}
                animate={{
                  opacity: 1,
                  rotate: [0, -5, 0],
                  y: [0, 40, 0],
                  x: 0
                }}
                transition={{
                  duration: 0.5,
                  delay: 2.5,
                  rotate: {
                    duration: 10,
                    repeat: Infinity,
                    ease: "easeInOut"
                  },
                  y: {
                    duration: 20,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }
                }}
                src={getPath("/card-assets/wands-01.png")}
                className="w-32 absolute -top-10 -left-24 hidden lg:block"
              />
              <motion.img
                initial={{ opacity: 0, rotate: -10, y: 0, x: 20 }}
                animate={{
                  opacity: 1,
                  rotate: [0, -5, 0],
                  y: [0, 40, 0],
                  x: 0
                }}
                transition={{
                  duration: 0.5,
                  delay: 3,
                  rotate: {
                    duration: 30,
                    repeat: Infinity,
                    ease: "easeInOut"
                  },
                  y: {
                    duration: 26,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }
                }}
                src={getPath("/card-assets/cups-01.png")}
                className="w-32 absolute bottom-2 -left-24 hidden lg:block"
              />
            </>
          )}

          <Card className="w-full overflow-auto relative z-10 bg-background border border-primary-foreground/20 rounded-2xl">
            <div className="absolute inset-0 z-0">
              <Textures type="paper-1" />
            </div>
            <CardHeader className="bg-opacity-50 relative z-10">
              <CardTitle className="text-3xl text-center font-semibold mt-2 text-primary-foreground w-full">
                Begin Your Journey
              </CardTitle>
            </CardHeader>
            <CardContent className="relative z-10 mt-2">
              <Form {...form}>
                <form
                  onSubmit={form.handleSubmit(onSubmit)}
                  className="pt-4 font-rosarivo max-w-2xl mx-auto"
                >
                  <div>
                    <h3 className="text-2xl text-center font-semibold mb-2 text-primary-foreground w-full">
                      Pick Your Guide
                    </h3>
                    <p className="text-primary-foreground text-center mb-8">
                      Your guide will be your personal AI tarot reader, helping
                      you navigate your journey through, and learn about the
                      tarot cards. You can change your guide at any time.
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 items-center w-full mb-8">
                      {guides.map((guide) => (
                        <div
                          className={cn(
                            "flex gap-4 md:gap-6 p-1 pr-4 border rounded-lg w-full transition-all duration-400 ease-in-out cursor-pointer relative",
                            selectedGuide === guide.id
                              ? "border-rose-600 bg-rose-50/30 shadow"
                              : "border-primary-foreground/20 bg-rose-50/0 hover:border-rose-600/30"
                          )}
                          key={guide.id}
                          onClick={() => handleGuideSelect(guide.id)}
                        >
                          <motion.div
                            className={`w-2/5 shrink-0 aspect-[4/7] z-50 inline-block bg-primary-dark border-4 border-primary-dark rounded-lg shadow-md overflow-hidden bg-cover bg-center bg-no-repeat origin-center`}
                            style={{
                              backgroundImage: `url(${guide.image})`
                            }}
                          />
                          <div className="flex flex-col justify-center my-4">
                            <h3 className="text-2xl font-semibold text-primary-foreground mb-1">
                              {guide.name}
                            </h3>
                            {/* {selectedGuide === guide.id && (
                              <span className="text-rose-600 pointer-events-none absolute right-6 font-sans uppercase text-sm">
                                Selected
                              </span>
                            )} */}
                            <p className=" text-primary-foreground">
                              {guide.description}
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* <hr className="border-primary-foreground/20 my-10" /> */}
                  <div className="space-y-6 mt-6">
                    <div className="flex flex-col md:flex-row md:gap-4">
                      <div className="flex-1">
                        <TarotFormField
                          name="name"
                          label="Your Name"
                          control={form.control}
                          placeholder="Name"
                        />
                        {form.formState.errors.name && (
                          <p className="text-red-700 relative -top-3 text-sm">
                            {form.formState.errors.name.message}
                          </p>
                        )}
                      </div>
                      <div className="flex-1 mt-6 md:mt-0">
                        <TarotFormField
                          name="email"
                          label="Your Email"
                          control={form.control}
                          placeholder="your@email.com"
                          type="email"
                        />
                        {form.formState.errors.email && (
                          <p className="text-red-700 relative -top-3 text-sm">
                            {form.formState.errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <TarotFormField
                      name="password"
                      label="Password"
                      control={form.control}
                      placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                      type="password"
                    />
                    {form.formState.errors.password && (
                      <p className="text-red-700 relative -top-3 text-sm">
                        {form.formState.errors.password.message}
                      </p>
                    )}
                  </div>

                  <div className="mt-10 mb-8 flex flex-row justify-between items-center">
                    <PushableButton
                      text={
                        mutation.isPending
                          ? "Processing..."
                          : "Begin Your Journey"
                      }
                      className="w-full"
                      isLoading={mutation.isPending}
                      type="submit"
                      disabled={mutation.isPending}
                    />
                  </div>
                </form>
              </Form>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
}
