import { useQuery } from "@tanstack/react-query";
import { queryClient } from "@/utils/queryClient";

import { fetchTarotCards } from "@/utils/api";
import { Card } from "@/types/Card";
import Layout from "@/components/layout";
import { Link } from "react-router-dom";
import { cn } from "@/lib/utils";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { CardLoader } from "@/components/CardLoader";
import { getPath } from "@/utils/paths";

const TarotCard = ({
  card,
  isMobile = false
}: {
  card: Card;
  isMobile?: boolean;
}) => {
  if (isMobile) {
    return (
      <Link
        to={`/deck/${card.id}`}
        className="flex items-center space-x-4 p-2 bg-[#f4e7d8] rounded-lg border border-black/40 hover:bg-[#f0dfd0] transition-colors"
      >
        <div className="relative w-16 h-16 flex-shrink-0">
          <img
            src={card.imageUrl ?? ""}
            alt={card.name}
            className="w-full h-full object-cover rounded-md border border-black/40"
          />
          <div
            className="absolute inset-0 bg-cover mix-blend-multiply pointer-events-none rounded-md"
            style={{ backgroundImage: `url('${getPath("/texture.jpg")}')` }}
          />
        </div>
        <div className="flex-grow">
          <div className="flex items-center space-x-2">
            <span className="font-rosarivo text-xs">{card.name}</span>
            <span className="text-xs text-orange-950/70">
              #{card.cardNumber}
            </span>
          </div>
          {card.suit && (
            <span className="text-xs text-orange-950/70">{card.suit}</span>
          )}
        </div>
      </Link>
    );
  }

  return (
    <Link
      to={`/deck/${card.id}`}
      className="transform transition-all duration-300 hover:scale-105"
    >
      <motion.div
        className="rounded-lg lg:rounded-2xl p-4 overflow-hidden bg-[#f4e7d8] border border-black border-opacity-40"
        style={{
          width: "170px",
          transformStyle: "preserve-3d"
        }}
      >
        <div className="relative">
          <div className="flex items-center absolute w-full justify-center z-10">
            <span className="absolute flex items-center justify-center font-rosarivo text-xs border border-t-0 border-black/60 bg-[#f4e7d8] z-50 font-left-0 mx-4 w-8 h-4 mt-4 rounded-br-full rounded-bl-full">
              <span className="z-10 text-xs -mt-2">{card.cardNumber}</span>
            </span>
          </div>
          <div className="relative">
            <img
              src={card.imageUrl ?? ""}
              className="object-cover border border-black rounded-t-md"
              alt={card.name}
            />
            <div className="w-full flex justify-center text-center">
              <div className="bg-[#f4e7d8] border border-t-0 border-black w-full px-2">
                <span className="font-rosarivo text-xs">{card.name}</span>
              </div>
            </div>
          </div>
          <div
            className="absolute z-50 inset-0 bg-cover mix-blend-multiply"
            style={{ backgroundImage: `url('${getPath("/texture.jpg")}')` }}
          ></div>
        </div>
      </motion.div>
    </Link>
  );
};

const CardRow = ({
  cards,
  isMobile,
  className
}: {
  cards: Card[];
  isMobile: boolean;
  className?: string;
}) => {
  if (isMobile) {
    return (
      <div className="space-y-2">
        {cards.map((card) => (
          <TarotCard key={card.id} card={card} isMobile={true} />
        ))}
      </div>
    );
  }

  return (
    <div
      className={cn(
        "grid gap-4 justify-items-center",
        "grid-cols-7 max-md:grid-cols-1",
        className
      )}
    >
      {cards.map((card) => (
        <div key={card.id} className="w-full flex justify-center">
          <TarotCard card={card} />
        </div>
      ))}
    </div>
  );
};

export default function Deck() {
  // Add this custom hook for media queries
  const useMediaQuery = (query: string) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [matches, query]);

    return matches;
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  const {
    data: cards,
    isLoading,
    error
  } = useQuery({
    queryKey: ["tarotCards"],
    queryFn: fetchTarotCards,
    staleTime: 5 * 60 * 1000,
    initialData: () => {
      // Check if we have valid cached data
      const cached = queryClient.getQueryData(["tarotCards"]);
      if (Array.isArray(cached) && cached.length === 78) {
        return cached;
      }
      return undefined;
    },
    initialDataUpdatedAt: () => {
      // If we have initial data, return when it was last updated
      const state = queryClient.getQueryState(["tarotCards"]);
      return state?.dataUpdatedAt;
    }
  });

  if (isLoading) {
    return (
      <Layout>
        <CardLoader loadingText="Loading your deck..." />
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div>An error occurred: {(error as Error).message}</div>
      </Layout>
    );
  }

  if (!cards) return null;

  // Major Arcana
  const majorArcana = cards
    .filter((card: Card) => card.arcanaType === "Major Arcana")
    .sort((a, b) => a.cardNumber - b.cardNumber);

  // Minor Arcana by suit
  const cups = cards
    .filter((card: Card) => card.suit === "Cups")
    .sort((a, b) => a.cardNumber - b.cardNumber);
  const pentacles = cards
    .filter((card: Card) => card.suit === "Pentacles")
    .sort((a, b) => a.cardNumber - b.cardNumber);
  const swords = cards
    .filter((card: Card) => card.suit === "Swords")
    .sort((a, b) => a.cardNumber - b.cardNumber);
  const wands = cards
    .filter((card: Card) => card.suit === "Wands")
    .sort((a, b) => a.cardNumber - b.cardNumber);

  // Split Major Arcana
  const theFool = majorArcana.find((card) => card.cardNumber === 0);
  const otherMajorArcana = majorArcana.filter((card) => card.cardNumber !== 0);
  const firstRow = otherMajorArcana.slice(0, 7);
  const secondRow = otherMajorArcana.slice(7, 14);
  const thirdRow = otherMajorArcana.slice(14, 21);

  // Split Minor Arcana suits into rows
  const splitIntoRows = (cards: Card[]) => {
    return [cards.slice(0, 7), cards.slice(7, 14)];
  };

  const [cupsFirstRow, cupsSecondRow] = splitIntoRows(cups);
  const [pentaclesFirstRow, pentaclesSecondRow] = splitIntoRows(pentacles);
  const [swordsFirstRow, swordsSecondRow] = splitIntoRows(swords);
  const [wandsFirstRow, wandsSecondRow] = splitIntoRows(wands);

  const SuitSection = ({
    title,
    firstRow,
    secondRow
  }: {
    title: string;
    firstRow: Card[];
    secondRow: Card[];
  }) => (
    <div className="mb-16">
      <h2 className="text-2xl font-bold mb-4 text-center">{title}</h2>
      <div className="grid gap-4">
        <CardRow cards={firstRow} isMobile={isMobile} />
        <CardRow cards={secondRow} isMobile={isMobile} />
      </div>
    </div>
  );

  return (
    <Layout disablePadding>
      <div className="space-y-16 md:pb-12  overflow-hidden">
        <h2 className="text-3xl font-bold mb-6 text-center">Major Arcana</h2>
        <hr className="border-orange-950/10 mb-10" />
        <div className="p-4">
          {/* The Fool */}
          {theFool && (
            <div className="mb-4">
              <div className="md:flex md:justify-center">
                <div className="w-full md:w-auto">
                  <TarotCard card={theFool} isMobile={isMobile} />
                </div>
              </div>
            </div>
          )}
          {/* Major Arcana Rows */}
          <div className="grid gap-4 mb-16">
            <CardRow cards={firstRow} isMobile={isMobile} />
            <CardRow cards={secondRow} isMobile={isMobile} />
            <CardRow cards={thirdRow} isMobile={isMobile} />
          </div>
        </div>
        <div>
          <hr className="border-orange-950/10 mb-16" />
          <h2 className="text-3xl font-bold my-6 text-center">Minor Arcana</h2>
        </div>
        {/* Minor Arcana Sections */}
        <SuitSection
          title="Cups"
          firstRow={cupsFirstRow}
          secondRow={cupsSecondRow}
        />
        <SuitSection
          title="Pentacles"
          firstRow={pentaclesFirstRow}
          secondRow={pentaclesSecondRow}
        />
        <SuitSection
          title="Swords"
          firstRow={swordsFirstRow}
          secondRow={swordsSecondRow}
        />
        <SuitSection
          title="Wands"
          firstRow={wandsFirstRow}
          secondRow={wandsSecondRow}
        />
      </div>
    </Layout>
  );
}
