import { useNavigate, useLocation } from "react-router-dom";
import { PushableButton } from "./PushableButton";
import Container from "@/components/Container";
import Logo from "./Logo";

export default function AuthNavbar() {
  const navigate = useNavigate();
  const location = useLocation();

  // Determine if we're on the login or register page
  const isLoginPage = location.pathname.includes("/login");

  return (
    <header className="font-rosarivo relative z-10 mt-[var(--safe-area-top)]">
      <Container>
        <nav
          aria-label="Global"
          className="flex py-4 items-center justify-between"
        >
          <Logo />
          <div className="flex lg:flex-1 lg:justify-end space-x-8">
            {isLoginPage ? (
              <PushableButton
                text="Register"
                onClick={() => navigate("/register")}
                size="small"
              />
            ) : (
              <PushableButton
                text="Login"
                onClick={() => navigate("/login")}
                size="small"
              />
            )}
          </div>
        </nav>
      </Container>
    </header>
  );
}
