import * as React from "react";
import { useState } from "react";
import {
  BookOpen,
  Calendar,
  Grip,
  HeartHandshake,
  LogOut,
  MessageCircleHeart,
  Send,
  Stars,
  Settings
} from "lucide-react";
import { NavMain } from "@/components/nav-main";
import { NavSecondary } from "@/components/nav-secondary";
import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem
} from "@/components/ui/sidebar";
import { cn } from "@/lib/utils";
import { useUserStore } from "@/stores/useUserStore";
import { Link, useLocation } from "react-router-dom";
import CreditsModal from "@/components/CreditsModal";
import SettingsModal from "@/components/SettingsModal";
import { getPath, getAssetPath } from "@/utils/paths";
import { getPlatform } from "@/utils/platform";

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const user = useUserStore((state) => state.user);
  const location = useLocation();
  const [showCreditsModal, setShowCreditsModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const { isNative } = getPlatform();

  // if (!user) return null; // Don't render sidebar if user is not authenticated

  const currentPath = location.pathname;

  const handleCreditsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowCreditsModal(true);
  };

  const handleSettingsClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowSettingsModal(true);
  };

  // Create the secondary navigation items
  const secondaryNavItems = [
    {
      title: `${user?.messageCredits ?? 0} Credits`,
      url: "#",
      icon: Stars,
      isActive: false,
      onClick: handleCreditsClick
    },
    {
      title: "Feedback",
      url: getPath("/feedback"),
      icon: Send,
      isActive: currentPath === getPath("/feedback")
    }
  ];

  // Only add the Settings option if on a native device
  if (isNative) {
    secondaryNavItems.push({
      title: "Settings",
      url: "#",
      icon: Settings,
      isActive: false,
      onClick: handleSettingsClick
    });
  }

  // Add the Logout option
  secondaryNavItems.push({
    title: "Logout",
    url: getPath("/logout"),
    icon: LogOut,
    isActive: false
  });

  const data = {
    user: {
      name: user?.name,
      email: user?.email,
      avatar: "/avatars/default.jpg"
    },
    navMain: [
      {
        title: "Daily Draw",
        url: getPath("/daily"),
        icon: Calendar,
        isActive: currentPath === getPath("/daily")
      },
      // {
      //   title: "Spreads",
      //   url: "/spreads",
      //   icon: LayoutGrid
      // },
      {
        title: "Readings",
        url: getPath("/readings"),
        icon: MessageCircleHeart,
        isActive: currentPath.includes(getPath("/readings"))
      },
      {
        title: "Deck",
        url: getPath("/deck"),
        icon: Grip,
        isActive: currentPath === getPath("/deck")
      },
      {
        title: "Guide",
        url: getPath("/guides"),
        icon: HeartHandshake,
        isActive: currentPath === getPath("/guides")
      },
      {
        title: "Learn",
        url: getPath("/learn"),
        icon: BookOpen,
        isActive: currentPath.includes(getPath("/learn"))
      }
      // Commented out submenu items - can be uncommented if needed later
      // {
      //   title: "Learn",
      //   url: "/learn",
      //   icon: BookOpen,
      //   items: [
      //     {
      //       title: "Tarot Basics",
      //       url: "/learn/basics"
      //     },
      //     {
      //       title: "Card Meanings",
      //       url: "/learn/meanings"
      //     },
      //     {
      //       title: "Spreads",
      //       url: "/learn/spreads"
      //     }
      //   ]
      // }
    ],
    navSecondary: secondaryNavItems
  };

  return (
    <>
      <Sidebar variant="inset" {...props}>
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <SidebarMenuButton size="lg" asChild>
                <Link to={getPath("/daily")}>
                  <div className="flex aspect-square size-8 items-center justify-center rounded-lg bg-primary-darker text-sidebar-primary-foreground">
                    <img
                      className="object-contain p-1"
                      src={getAssetPath("/logo.png")}
                      width={40}
                      height={40}
                      alt="Logo"
                    />
                  </div>
                  <div className="grid flex-1 text-left text-sm leading-tight">
                    <span
                      className={cn(
                        "font-bold relative text-orange-950 text-md ml-1"
                      )}
                    >
                      Your Unique Tarot
                    </span>
                  </div>
                </Link>
              </SidebarMenuButton>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <NavMain items={data.navMain} />
          <NavSecondary items={data.navSecondary} className="mt-auto" />
        </SidebarContent>
        {/* <SidebarFooter>
          <NavUser user={data.user} />
        </SidebarFooter> */}
      </Sidebar>

      {/* Credits Modal */}
      <CreditsModal
        isOpen={showCreditsModal}
        onClose={() => setShowCreditsModal(false)}
      />

      {/* Settings Modal */}
      <SettingsModal
        isOpen={showSettingsModal}
        onClose={() => setShowSettingsModal(false)}
      />
    </>
  );
}
