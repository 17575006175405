import { useMutation } from "@tanstack/react-query";
import { loginUser } from "@/utils/api";
import { queryClient } from "@/utils/queryClient";
import { useUserStore } from "@/stores/useUserStore";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { User } from "lucide-react";
import { useNavigate } from "react-router-dom";
import AuthNavbar from "@/components/AuthNavbar";
import { useState, useLayoutEffect } from "react";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from "@/components/ui/form";
import { PushableButton } from "@/components/PushableButton";
import { Textures } from "@/components/Textures";
import Navbar from "@/components/navbar";
import { getPlatform, storeAuthToken, getAuthToken } from "@/utils/platform";
import { getPath } from "@/utils/paths";

// Define the schema for the form
const formSchema = z.object({
  email: z.string().email("Invalid email address"),
  password: z.string().min(6, "Password must be at least 6 characters")
});

type FormData = z.infer<typeof formSchema>;

export default function LoginPage() {
  const navigate = useNavigate();
  const setUser = useUserStore((state) => state.setUser);
  const user = useUserStore((state) => state.user);
  const [message, setMessage] = useState("");
  const { isNative } = getPlatform();
  const [isRedirecting, setIsRedirecting] = useState(false);

  // Check if user is already logged in
  useLayoutEffect(() => {
    const checkAuthStatus = async () => {
      // If already redirecting, don't check again
      if (isRedirecting) {
        return;
      }

      try {
        // Check if there's an auth token
        const token = await getAuthToken();
        console.log("Login page - Auth token check:", !!token);

        // If token exists or user is already in the store, redirect to /app/daily
        if ((token || user) && !isRedirecting) {
          console.log("Login page - Redirecting to daily page");
          setIsRedirecting(true);
          navigate(getPath("/daily"));
        } else {
          console.log("Login page - No token or user, staying on login page");
        }
      } catch (error) {
        console.error("Error checking authentication status:", error);
      }
    };

    // Only run this check once on mount
    checkAuthStatus();
  }, [navigate, user, isRedirecting]);

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: ""
    }
  });

  const loginMutation = useMutation({
    mutationFn: loginUser,
    onSuccess: async (data) => {
      // Save token and user data using the new storage function
      await storeAuthToken(data.token);
      setUser(data.user);
      queryClient.invalidateQueries({ queryKey: ["currentUser"] });
      navigate(getPath("/daily"));
    },
    onError: (error: any) => {
      console.error("Login error:", error);
      setMessage(
        error?.response?.data?.error || "An error occurred during login"
      );
    }
  });

  const onSubmit = async (values: FormData) => {
    console.log("login");
    console.log(process.env.NODE_ENV);
    try {
      await loginMutation.mutateAsync(values);
    } catch (error) {
      console.error("Error:", error);
      setMessage("An error occurred. Please try again.");
    }
  };

  return (
    <>
      {isNative ? <Navbar /> : <AuthNavbar />}
      <div className="flex justify-center relative overflow-y-auto ">
        <div className="flex flex-1 flex-col justify-center px-4 py-4 md:py-6 sm:px-6 lg:flex-none lg:px-20 xl:px-24 z-10 relative">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <Card className="w-full z-10 relative shadow-md bg-background border border-primary-foreground/20 rounded-2xl">
              <Textures type="paper-1" />
              <CardHeader className="relative z-10">
                <CardTitle className="font-bold tracking-tight text-primary-foreground sm:text-2xl sm:leading-tight flex items-center">
                  <User className="h-6 w-6 text-primary-foreground mr-2 relative -top-1" />
                  <span>Login</span>
                </CardTitle>
              </CardHeader>
              <CardContent className="pt-10 z-10 relative">
                <Form {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <div className="space-y-6">
                      <FormField
                        control={form.control}
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel htmlFor="email">Email</FormLabel>
                            <FormControl>
                              <Input
                                id="email"
                                type="email"
                                placeholder="your@email.com"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={form.control}
                        name="password"
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel htmlFor="password">Password</FormLabel>
                            <FormControl>
                              <Input
                                id="password"
                                type="password"
                                placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </div>
                    <div className="mt-10 mb-8 flex justify-between items-center">
                      <PushableButton
                        text={
                          loginMutation.isPending ? "Logging in..." : "Login"
                        }
                        isLoading={loginMutation.isPending}
                        type="submit"
                        disabled={loginMutation.isPending}
                      />
                    </div>
                  </form>
                </Form>
                {message && (
                  <p className="mt-4 text-sm text-center text-red-500">
                    {message}
                  </p>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>

      {process.env.NODE_ENV === "development" && (
        <div className="mt-4 mb-4 text-center opacity-50">
          <div className="text-xs flex flex-col gap-2">
            <button
              onClick={() => navigate("/debug")}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              Developer Menu
            </button>
            <button
              onClick={() => navigate("/reset-onboarding")}
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              Reset Onboarding
            </button>
          </div>
        </div>
      )}
    </>
  );
}
