import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { Card as CardType } from "@/types/Card";
import { Input } from "@/components/ui/input";
import { PushableButton } from "@/components/PushableButton";

interface CardSelectionDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  cards: CardType[];
  onCardSelect: (cardId: number, isReversed: boolean) => void;
}

export function CardSelectionDialog({
  open,
  onOpenChange,
  cards,
  onCardSelect
}: CardSelectionDialogProps) {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isReversed, setIsReversed] = React.useState(false);
  const searchInputRef = React.useRef<HTMLInputElement>(null);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const gridRef = React.useRef<HTMLDivElement>(null);

  // Save scroll position before state changes
  const handleBeforeStateChange = () => {
    if (gridRef.current) {
      setScrollPosition(gridRef.current.scrollTop);
    }
  };

  // Restore scroll position after state changes
  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition, isReversed]);

  const handleOpenAutoFocus = (e: Event) => {
    e.preventDefault();
    searchInputRef.current?.focus();
  };

  const filteredCards = React.useMemo(() => {
    if (!cards) return [];
    const query = searchQuery.toLowerCase();

    return cards
      .filter((card) => {
        // Standard name search
        if (card.name.toLowerCase().includes(query)) return true;

        // Card number search
        if (card.cardNumber.toString().includes(query)) return true;

        // Suit search
        if (card.suit?.toLowerCase().includes(query)) return true;

        // Combined suit and number search patterns:
        // "wands 8", "8 wands", "wands8"
        if (card.suit) {
          const suitAndNumber = `${card.suit.toLowerCase()}${card.cardNumber}`;
          const suitSpaceNumber = `${card.suit.toLowerCase()} ${
            card.cardNumber
          }`;
          const numberSpaceSuit = `${
            card.cardNumber
          } ${card.suit.toLowerCase()}`;

          return (
            suitAndNumber.includes(query) ||
            suitSpaceNumber.includes(query) ||
            numberSpaceSuit.includes(query)
          );
        }

        // Major arcana number search with variations:
        // "major 1", "major arcana 1", "arcana 1"
        if (!card.suit) {
          const majorNumber = `major ${card.cardNumber}`;
          const arcanaNumber = `arcana ${card.cardNumber}`;
          const majorArcanaNumber = `major arcana ${card.cardNumber}`;

          return (
            majorNumber.includes(query) ||
            arcanaNumber.includes(query) ||
            majorArcanaNumber.includes(query)
          );
        }

        return false;
      })
      .sort((a, b) => a.id - b.id);
  }, [cards, searchQuery]);

  const CardGrid = () => (
    <div className="overflow-y-auto scrollbar-custom flex-1" ref={gridRef}>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 pr-4">
        {filteredCards.map((card) => (
          <div
            key={card.id}
            className="flex flex-col gap-2 p-3 cursor-pointer rounded-xl border border-border hover:bg-accent/50 transition-colors"
            onClick={() => {
              onCardSelect(card.id, isReversed);
              onOpenChange(false);
            }}
          >
            <div className="relative aspect-[2/3] overflow-hidden rounded-lg">
              <img
                src={card.imageUrl ?? ""}
                alt={card.name}
                className={`w-full h-full object-cover transition-transform duration-300 ${
                  isReversed ? "rotate-180" : ""
                }`}
              />
            </div>
            <div className="flex flex-col">
              <h3 className="text-base font-semibold truncate">{card.name}</h3>
              <p className="text-sm text-muted-foreground">
                {card.suit
                  ? `${card.suit} - ${card.cardNumber}`
                  : `Major Arcana - ${card.cardNumber}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent
        onOpenAutoFocus={handleOpenAutoFocus}
        className="sm:max-w-[1200px] h-[80vh] flex flex-col overflow-hidden"
      >
        <DialogHeader>
          <DialogTitle className="text-primary-foreground mt-2">
            Select Your Card
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-4">
          <Input
            ref={searchInputRef}
            placeholder="Search cards..."
            value={searchQuery}
            className="text-primary-foreground"
            onChange={(e) => setSearchQuery(e.target.value)}
            autoComplete="off"
          />

          <div className="flex justify-center">
            <PushableButton
              text={isReversed ? "Show Cards Upright" : "Show Cards Reversed"}
              size="small"
              onClick={() => {
                handleBeforeStateChange();
                setIsReversed(!isReversed);
              }}
            />
          </div>
        </div>

        <CardGrid />
      </DialogContent>
    </Dialog>
  );
}
