import { useState } from "react";
import { AlertTriangle } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useUserStore } from "@/stores/useUserStore";
import { useNavigate } from "react-router-dom";
import { getPath } from "@/utils/paths";
import { deleteAccount } from "@/utils/api";
import { removeAuthToken } from "@/utils/platform";

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const SettingsModal: React.FC<SettingsModalProps> = ({ isOpen, onClose }) => {
  const { toast } = useToast();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { clearUser } = useUserStore();
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    try {
      setIsDeleting(true);

      await deleteAccount();

      // Clear user data and token
      clearUser();
      await removeAuthToken();

      toast({
        title: "Account Deleted",
        description: "Your account has been successfully deleted."
      });

      // Close modal and redirect to login
      onClose();
      navigate(getPath("/login"));
    } catch (error) {
      console.error("Error deleting account:", error);
      toast({
        title: "Error",
        description: "Failed to delete your account. Please try again later.",
        variant: "destructive"
      });
    } finally {
      setIsDeleting(false);
      setShowDeleteConfirmation(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Settings</DialogTitle>
          <DialogDescription>Manage your account settings</DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <div className="mt-4">
            <h3 className="text-lg font-medium">Account</h3>
            <div className="mt-2">
              <Button
                variant="destructive"
                onClick={() => setShowDeleteConfirmation(true)}
                className="w-full"
              >
                Delete Account
              </Button>
            </div>
          </div>
        </div>

        {/* Delete Account Confirmation Dialog */}
        {showDeleteConfirmation && (
          <Dialog
            open={showDeleteConfirmation}
            onOpenChange={setShowDeleteConfirmation}
          >
            <DialogContent className="sm:max-w-md">
              <DialogHeader>
                <DialogTitle className="flex items-center gap-2">
                  <AlertTriangle className="h-5 w-5 text-red-500" />
                  Delete Account
                </DialogTitle>
                <DialogDescription>
                  Are you sure you want to delete your account? This action
                  cannot be undone and all your data will be permanently lost.
                </DialogDescription>
              </DialogHeader>

              <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:justify-between">
                <Button
                  variant="outline"
                  onClick={() => setShowDeleteConfirmation(false)}
                  className="sm:flex-1"
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={handleDeleteAccount}
                  disabled={isDeleting}
                  className="sm:flex-1"
                >
                  {isDeleting ? "Deleting..." : "Yes, Delete My Account"}
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;
