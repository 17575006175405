import { Capacitor } from "@capacitor/core";
import {
  Purchases,
  PurchasesPackage,
  PurchasesStoreProduct
} from "@revenuecat/purchases-capacitor";
import { getPlatform } from "./platform";
import { api } from "./api";
import { queryClient } from "./queryClient";

// RevenueCat API keys - you'll need to replace these with your actual API keys
const REVENUECAT_API_KEYS = {
  apple: import.meta.env.VITE_REVENUECAT_IOS_KEY || "YOUR_IOS_API_KEY",
  google: import.meta.env.VITE_REVENUECAT_ANDROID_KEY || "YOUR_ANDROID_API_KEY"
};

// Product identifiers
export const PRODUCT_IDS = {
  CREDITS_PACKAGE: "credits_222_v2"
};

// Initialize RevenueCat
export const initializePurchases = async (userId?: string) => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    // Get the platform
    const platform = getPlatform();

    // Configure RevenueCat with the appropriate API key
    await Purchases.configure({
      apiKey: platform.isIOS
        ? REVENUECAT_API_KEYS.apple
        : REVENUECAT_API_KEYS.google
    });

    // If a user ID is provided, log in the user
    if (userId) {
      await Purchases.logIn({ appUserID: userId });
    }

    // Set up purchase listeners to sync with backend
    setupPurchaseListeners();

    // Verify any existing purchases
    await verifyExistingPurchases();

    console.log("RevenueCat initialized successfully");
  } catch (error) {
    console.error("Error initializing RevenueCat:", error);
  }
};

/**
 * Verifies existing purchases on app startup
 * This ensures credits are properly allocated even if previous validation failed
 */
const verifyExistingPurchases = async () => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    // Get customer info from RevenueCat
    const response = await Purchases.getCustomerInfo();
    const customerInfo = response.customerInfo;

    // Check if there are any active purchases that need validation
    if (
      Object.keys(customerInfo.entitlements.active || {}).length > 0 ||
      (customerInfo.allPurchasedProductIdentifiers || []).length > 0
    ) {
      // Sync purchases with backend to ensure credits are allocated
      await syncPurchasesWithBackend(customerInfo);
    }
  } catch (error) {
    console.error("Error verifying existing purchases:", error);
  }
};

/**
 * Sets user attributes in RevenueCat
 * @param attributes Object containing user attributes
 * @param syncImmediately Whether to sync attributes immediately (default: true)
 */
export const setUserAttributes = async (
  attributes: {
    email?: string;
    name?: string;
    [key: string]: string | undefined;
  },
  syncImmediately = true
) => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    // Set reserved attributes using dedicated methods if available
    if (attributes.email) {
      await Purchases.setEmail({ email: attributes.email });
    }

    if (attributes.name) {
      await Purchases.setDisplayName({ displayName: attributes.name });
    }

    // Convert all attributes to strings and filter out undefined values
    const stringAttributes: Record<string, string> = {};

    Object.entries(attributes).forEach(([key, value]) => {
      // Skip email and name as they're handled separately
      if (key !== "email" && key !== "name" && value !== undefined) {
        stringAttributes[key] = String(value);
      }
    });

    // Set any additional custom attributes
    if (Object.keys(stringAttributes).length > 0) {
      await Purchases.setAttributes(stringAttributes);
    }

    // Sync attributes immediately if requested
    if (syncImmediately) {
      await Purchases.syncAttributesAndOfferingsIfNeeded();
    }

    console.log("User attributes set successfully");
  } catch (error) {
    console.error("Error setting user attributes:", error);
  }
};

// Set up listeners for purchase events
const setupPurchaseListeners = () => {
  // Listen for purchases
  Purchases.addCustomerInfoUpdateListener(async (info) => {
    try {
      // Sync purchases with backend
      await syncPurchasesWithBackend(info);
    } catch (error) {
      console.error("Error syncing purchases with backend:", error);
    }
  });
};

// Sync purchases with backend
const syncPurchasesWithBackend = async (customerInfo: any) => {
  if (!Capacitor.isNativePlatform()) {
    return;
  }

  try {
    console.log("Syncing purchases with backend");
    console.log("Customer info:", JSON.stringify(customerInfo));

    // Extract transactions from nonSubscriptionTransactions which has the actual transaction IDs
    const transactions: Array<{ transactionId: string }> = [];

    if (
      customerInfo.nonSubscriptionTransactions &&
      Array.isArray(customerInfo.nonSubscriptionTransactions) &&
      customerInfo.nonSubscriptionTransactions.length > 0
    ) {
      // Get all transactions
      customerInfo.nonSubscriptionTransactions.forEach((transaction: any) => {
        if (transaction.transactionIdentifier || transaction.revenueCatId) {
          transactions.push({
            transactionId:
              transaction.transactionIdentifier || transaction.revenueCatId
          });
        }
      });
    }

    console.log("Extracted transactions:", transactions);

    // Process each transaction
    if (transactions.length > 0) {
      // Sort by most recent first (assuming they're in chronological order in the array)
      const sortedTransactions = [...transactions].reverse();

      // Try to validate the most recent transaction first
      let validated = false;

      for (const transaction of sortedTransactions) {
        try {
          console.log(
            `Validating transaction: ${transaction.transactionId} for product: ${PRODUCT_IDS.CREDITS_PACKAGE}`
          );

          // Send to backend for validation
          const response = await api.post("/payments/validate-purchase", {
            transactionId: transaction.transactionId,
            productId: PRODUCT_IDS.CREDITS_PACKAGE
          });

          // If we get here, validation succeeded
          console.log("Transaction validated successfully:", response.data);
          validated = true;

          // Refresh user data
          await queryClient.invalidateQueries({ queryKey: ["currentUser"] });

          // Break after first successful validation
          break;
        } catch (error: any) {
          console.log(
            `Error validating transaction ${transaction.transactionId}:`,
            error.message
          );

          // If it's a 400 error, it might be already processed, so we can consider it validated
          if (error.status === 400 || error.response?.status === 400) {
            console.log("Transaction may have been already processed");
            validated = true;

            // Still refresh user data
            await queryClient.invalidateQueries({ queryKey: ["currentUser"] });
            break;
          }

          // Continue to the next transaction if this one failed
          continue;
        }
      }

      if (!validated) {
        console.error("Failed to validate any transactions");
      }
    } else {
      console.log("No transactions found to validate");
    }
  } catch (error) {
    console.error("Error syncing purchases with backend:", error);
  }
};

// Get available packages
export const getAvailablePackages = async (
  productIds: string[]
): Promise<
  | PurchasesPackage[]
  | {
      identifier: string;
      packageType: string;
      product: PurchasesStoreProduct;
      offeringIdentifier: string;
    }[]
> => {
  if (!Capacitor.isNativePlatform()) {
    console.log("RevenueCat is only available on native platforms");
    return [];
  }

  try {
    const offerings = await Purchases.getOfferings();

    if (!offerings.current) {
      // If no offerings are available, fetch products directly
      const { products } = await Purchases.getProducts({
        productIdentifiers: productIds
      });

      // Convert to custom package format
      return products.map((product) => ({
        identifier: product.identifier,
        packageType: "CUSTOM",
        product: product,
        offeringIdentifier: "direct"
      }));
    }

    return offerings.current.availablePackages;
  } catch (error) {
    console.error("Error getting packages:", error);
    return [];
  }
};

// Purchase a package
export const purchasePackage = async (packageToPurchase: PurchasesPackage) => {
  if (!Capacitor.isNativePlatform()) {
    throw new Error("RevenueCat is only available on native platforms");
  }

  try {
    const result = await Purchases.purchasePackage({
      aPackage: packageToPurchase
    });

    // Validate purchase with your backend immediately after purchase
    await validateAndSyncPurchase(
      result.customerInfo,
      packageToPurchase.identifier
    );

    return result;
  } catch (error: any) {
    console.error("Error purchasing package:", error);

    // Handle specific error types
    if (error.code) {
      switch (error.code) {
        case "PURCHASE_CANCELLED_ERROR":
          // User cancelled the purchase - this is not a true error
          throw new Error("Purchase was cancelled by user");

        case "PRODUCT_NOT_AVAILABLE_ERROR":
          // Product is not available for purchase
          throw new Error("This product is no longer available for purchase");

        case "PURCHASE_INVALID_ERROR":
          // Purchase was invalid
          throw new Error(
            "There was an issue with your purchase. Please try again."
          );

        case "PURCHASE_NOT_ALLOWED_ERROR":
          // User is not allowed to make the purchase
          throw new Error(
            "You are not allowed to make this purchase. Check your device settings."
          );

        case "NETWORK_ERROR":
          // Network error occurred
          throw new Error(
            "Network error. Please check your connection and try again."
          );

        case "RECEIPT_ALREADY_IN_USE_ERROR":
          // Receipt is already in use by another subscriber
          throw new Error(
            "This purchase is already in use with another account."
          );

        default:
          // Unknown error
          throw new Error(
            `Purchase failed: ${error.message || "Unknown error"}`
          );
      }
    }

    // If no error code, just throw the original error
    throw error;
  }
};

// Validate and sync a specific purchase with your backend
const validateAndSyncPurchase = async (
  customerInfo: any,
  productId: string
) => {
  try {
    console.log("Validating purchase for product:", productId);
    console.log("Customer info:", JSON.stringify(customerInfo));

    // Get the transaction ID for this product from nonSubscriptionTransactions
    let transactionId = "";

    // First try to get from nonSubscriptionTransactions which has the actual transaction IDs
    if (
      customerInfo.nonSubscriptionTransactions &&
      Array.isArray(customerInfo.nonSubscriptionTransactions) &&
      customerInfo.nonSubscriptionTransactions.length > 0
    ) {
      // Find the latest transaction for this product
      const transactions = customerInfo.nonSubscriptionTransactions
        .filter((t: any) => t.productId === productId)
        .sort(
          (a: any, b: any) =>
            (b.purchaseDateMillis || 0) - (a.purchaseDateMillis || 0)
        );

      if (transactions.length > 0) {
        const transaction = transactions[0]; // Get the latest transaction
        transactionId =
          transaction.transactionIdentifier || transaction.revenueCatId || "";
      } else {
        // If no transaction for this product, get the latest transaction of any product
        const latestTransaction = customerInfo.nonSubscriptionTransactions.sort(
          (a: any, b: any) =>
            (b.purchaseDateMillis || 0) - (a.purchaseDateMillis || 0)
        )[0];

        if (latestTransaction) {
          transactionId =
            latestTransaction.transactionIdentifier ||
            latestTransaction.revenueCatId ||
            "";
        }
      }
    }

    // Fallback to the old method if we couldn't find a transaction ID
    if (!transactionId && customerInfo.allPurchaseDates) {
      // Look through all products
      for (const [, dateInfo] of Object.entries(
        customerInfo.allPurchaseDates
      )) {
        if (dateInfo && typeof dateInfo === "object") {
          const transactionIds = Object.keys(dateInfo as object);
          if (transactionIds.length > 0) {
            transactionId = transactionIds[transactionIds.length - 1];
            break;
          }
        }
      }
    }

    console.log(
      "Using transaction ID:",
      transactionId,
      "for product:",
      PRODUCT_IDS.CREDITS_PACKAGE
    );

    if (transactionId) {
      try {
        // Always use our standard product ID
        console.log(
          `Sending validation request with transactionId: ${transactionId}, productId: ${PRODUCT_IDS.CREDITS_PACKAGE}`
        );
        const response = await api.post("/payments/validate-purchase", {
          transactionId,
          productId: PRODUCT_IDS.CREDITS_PACKAGE
        });

        console.log("Validation response:", response.data);

        // Refresh user data
        await queryClient.invalidateQueries({ queryKey: ["currentUser"] });

        return response.data;
      } catch (error: any) {
        console.error("Error validating purchase:", error);

        // If it's a 400 error, it might be already processed (duplicate transaction)
        if (error.status === 400 || error.response?.status === 400) {
          console.log("Transaction may have been already processed");

          // Still refresh user data just in case
          await queryClient.invalidateQueries({ queryKey: ["currentUser"] });

          // Return a success response to prevent further error handling
          return { success: true, message: "Transaction already processed" };
        }

        // For other errors, throw to be handled by the caller
        throw error;
      }
    } else {
      console.error("No transaction ID found for validation");
      throw new Error("No transaction ID found for validation");
    }
  } catch (error) {
    console.error("Error validating purchase:", error);
    throw error;
  }
};

// Get customer info
export const getCustomerInfo = async () => {
  if (!Capacitor.isNativePlatform()) {
    return null;
  }

  try {
    const customerInfo = await Purchases.getCustomerInfo();
    return customerInfo;
  } catch (error) {
    console.error("Error getting customer info:", error);
    return null;
  }
};

// Restore purchases
export const restorePurchases = async () => {
  if (!Capacitor.isNativePlatform()) {
    return null;
  }

  try {
    const customerInfo = await Purchases.restorePurchases();

    // After restore, sync with your backend
    await syncPurchasesWithBackend(customerInfo.customerInfo);

    return customerInfo;
  } catch (error: any) {
    console.error("Error restoring purchases:", error);

    // Handle specific error types
    if (error.code) {
      switch (error.code) {
        case "NETWORK_ERROR":
          throw new Error(
            "Network error. Please check your connection and try again."
          );

        case "RECEIPT_ALREADY_IN_USE_ERROR":
          throw new Error(
            "These purchases are already in use with another account."
          );

        default:
          throw new Error(
            `Failed to restore purchases: ${error.message || "Unknown error"}`
          );
      }
    }

    throw error;
  }
};

// Check if user has any completed purchases
export const hasPurchasedCredits = async (): Promise<boolean> => {
  if (!Capacitor.isNativePlatform()) {
    return false;
  }

  try {
    const result = await Purchases.getCustomerInfo();
    const customerInfo = result.customerInfo;

    // Check if any of the credit products have been purchased
    return Object.keys(customerInfo.allPurchaseDates || {}).some(
      (productId) => productId === PRODUCT_IDS.CREDITS_PACKAGE
    );
  } catch (error) {
    console.error("Error checking purchase status:", error);
    return false;
  }
};

export default {
  initializePurchases,
  getAvailablePackages,
  purchasePackage,
  getCustomerInfo,
  restorePurchases,
  hasPurchasedCredits,
  PRODUCT_IDS
};
