import { create } from "zustand";
import { persist } from "zustand/middleware";
import { User } from "@/types/api";

interface UserStore {
  user: User | null;
  setUser: (user: User | null) => void;
  clearUser: () => void;
  updateCredits: (
    messageCredits?: number,
    cardGenerationCredits?: number
  ) => void;
}

export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) => {
        set({ user });
      },
      clearUser: () => {
        set({ user: null });
        // Use the proper persist API to clear storage
        useUserStore.persist.clearStorage();
      },
      updateCredits: (messageCredits) =>
        set((state) => {
          console.log("Updating credits:", {
            messageCredits
          });

          // If no user or no messageCredits, return unchanged state
          if (!state.user || messageCredits === undefined) {
            return { user: state.user };
          }

          // Set the credits to the new value instead of adding to existing credits
          return {
            user: {
              ...state.user,
              messageCredits: messageCredits
            }
          };
        })
    }),
    {
      name: "user-storage",
      partialize: (state) => ({ user: state.user })
    }
  )
);
