import axios from "axios";
import { LoginResponse, User } from "@/types/api";
import { useUserStore } from "@/stores/useUserStore";
import { ChatResponse } from "@/types/api";
import { Reading } from "@/types/api";
import { Card as CardType } from "@/types/Card";
import { queryClient } from "@/utils/queryClient";
import { Capacitor } from "@capacitor/core";
import { getAuthToken, removeAuthToken } from "./platform";
import { getPath } from "./paths";
import {
  Module,
  ModuleWithFlashcards,
  FlashcardWithProgress,
  FlashcardRating,
  ModuleProgress
} from "../types/flashcards";

type RegisterData = {
  name: string;
  email: string;
  password: string;
  preferredGuideId: number;
  packageType?: "free" | "premium";
  initialCredits?: number;
};

interface RegisterResponse {
  message: string;
  token: string;
  user: User;
}

const baseURL = import.meta.env.VITE_API_URL;

export const api = axios.create({
  baseURL: `${baseURL}/api`,
  headers: {
    "Content-Type": "application/json"
  }
});

api.interceptors.request.use(async (config) => {
  const token = await getAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.response?.status === 401 &&
      !error.config.url?.includes("/login") &&
      !error.config.url?.includes("/register")
    ) {
      await removeAuthToken();
      localStorage.removeItem("userId");
      queryClient.clear();

      if (!Capacitor.isNativePlatform()) {
        window.location.href = getPath("/login");
      }
    }
    return Promise.reject(error);
  }
);

export const getCardInterpretation = async (cardId: number) => {
  const response = await api.get(`/interpretations/cards/${cardId}`);
  return response.data;
};

export const createReading = async (data: {
  cardId: number;
  isReversed: boolean;
}) => {
  const response = await api.post("/readings", data);
  if (!response.data) {
    throw new Error("Failed to create reading");
  }
  return response.data;
};

export const getReading = async (readingId: number) => {
  const response = await api.get(`/readings/${readingId}`);
  return response.data;
};

interface ReadingsResponse {
  readings: Reading[];
  pagination: {
    total: number;
    limit: number;
    offset: number;
  };
}

export const fetchAllReadings = async (): Promise<ReadingsResponse> => {
  const response = await api.get("/readings");
  if (!response.data) {
    throw new Error("Failed to fetch readings");
  }
  return response.data;
};

export const sendUserMessage = async (
  content: string,
  conversationId: number
) => {
  const response = await api.post(`/messages`, {
    content,
    conversationId
  });
  if (!response.data) {
    throw new Error("Failed to send user message");
  }
  return response.data.message;
};

export const sendChatMessage = async (
  conversationId: number,
  cardName: string,
  conversation: string[]
): Promise<ChatResponse> => {
  try {
    const response = await api.post("/chat", {
      conversationId,
      cardName,
      conversation
    });
    if (!response.data) {
      throw new Error("Failed to send chat message");
    }
    return response.data;
  } catch (error) {
    console.error("Error in sendChatMessage:", error);
    throw error;
  }
};

export const checkIfDailyReadingExists = async () => {
  const response = await api.get(`/readings/daily`);
  return response.data;
};

export const loginUser = async (credentials: {
  email: string;
  password: string;
}): Promise<LoginResponse> => {
  const response = await api.post("/users/login", credentials);
  return response.data;
};

export const logoutUser = async () => {
  await removeAuthToken();

  localStorage.removeItem("user-storage");
  useUserStore.getState().clearUser();

  queryClient.removeQueries();

  if (Capacitor.isNativePlatform()) {
    try {
      const { Purchases } = await import("@revenuecat/purchases-capacitor");
      await Purchases.logOut();
    } catch (error) {
      console.error("Error with RevenueCat logout:", error);
    }
  }
};

export const getCurrentUser = async () => {
  const response = await api.get("/users/me");
  return response.data;
};

export const getReadingsByCard = async (
  cardMetadataId: number
): Promise<Reading[]> => {
  try {
    if (isNaN(cardMetadataId)) {
      throw new Error("Invalid card metadata ID");
    }
    const response = await api.get(`/readings/by-card/${cardMetadataId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching readings by card:", error);
    throw error;
  }
};

export const getCard = async (cardId: number): Promise<CardType> => {
  try {
    if (isNaN(cardId)) {
      throw new Error("Invalid card ID");
    }
    const response = await api.get(`/cards/${cardId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching card:", error);
    throw error;
  }
};

export const fetchTarotCards = async (): Promise<CardType[]> => {
  const response = await api.get("/cards");
  return response.data;
};

export const updatePreferredGuide = async (guideId: number): Promise<void> => {
  const response = await api.put("/users/preferred-guide", { guideId });
  return response.data;
};

export const toggleReadingFavorite = async (
  readingId: number
): Promise<void> => {
  const response = await api.put(`/readings/${readingId}/favorite`);
  return response.data;
};

export const sendFeedback = async (message: string): Promise<void> => {
  const response = await api.post("/feedback", { message });
  return response.data;
};

export const verifyEmail = async (token: string): Promise<void> => {
  const response = await api.get(`/users/verify/${token}`);
  return response.data;
};

export const registerUser = async (
  data: RegisterData
): Promise<RegisterResponse> => {
  const response = await api.post("/users/register", data);
  return response.data;
};

export const createCreditsPaymentIntent = async () => {
  const response = await api.post("/payments/create-credits-intent");
  return response.data;
};

export const addCreditsAfterPayment = async (paymentIntentId: string) => {
  const response = await api.post("/payments/add-credits", { paymentIntentId });
  return response.data;
};

// Flashcard API endpoints
export const flashcardsApi = {
  // Get all modules
  getModules: async (): Promise<Module[]> => {
    const response = await api.get("/flashcards/modules");
    return response.data;
  },

  // Get a specific module with its flashcards
  getModule: async (
    moduleId?: number
  ): Promise<ModuleWithFlashcards | null> => {
    if (moduleId) {
      const response = await api.get(`/flashcards/modules/${moduleId}`);
      return response.data;
    }
    return null;
  },

  // Get flashcards for a study session
  getStudyFlashcards: async (
    moduleId?: number
  ): Promise<FlashcardWithProgress[]> => {
    const params = new URLSearchParams();
    if (moduleId) params.append("moduleId", moduleId.toString());

    const response = await api.get(`/flashcards/study?${params.toString()}`);
    return response.data;
  },

  // Rate a flashcard
  rateFlashcard: async (flashcardId: number, rating: FlashcardRating) => {
    const response = await api.post("/flashcards/rate", {
      flashcardId,
      rating
    });
    return response.data;
  },

  // Get user progress across all modules
  getUserProgress: async (): Promise<ModuleProgress[]> => {
    const response = await api.get(`/flashcards/progress`);
    return response.data;
  }
};

// Account management
export const deleteAccount = async (): Promise<void> => {
  const response = await api.delete("/users/account");
  return response.data;
};
