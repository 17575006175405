import { useQuery } from "@tanstack/react-query";
import { getCurrentUser } from "@/utils/api";
import { useUserStore } from "@/stores/useUserStore";
import { useEffect, useState } from "react";
import axios from "axios";
import posthog from "posthog-js";
import { Capacitor } from "@capacitor/core";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { setUserAttributes } from "@/utils/purchases";
import { getAuthToken, removeAuthToken } from "@/utils/platform";

export function useAuth() {
  const { setUser, clearUser } = useUserStore();
  const [token, setToken] = useState<string | null>(null);
  const [isTokenLoaded, setIsTokenLoaded] = useState(false);

  // Load token on mount
  useEffect(() => {
    const loadToken = async () => {
      const authToken = await getAuthToken();
      setToken(authToken);
      setIsTokenLoaded(true);
    };

    loadToken();
  }, []);

  const query = useQuery({
    queryKey: ["currentUser"],
    queryFn: getCurrentUser,
    staleTime: 1000 * 60 * 5, // 5 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
    retry: 1,
    enabled: !!token && isTokenLoaded,
    refetchOnWindowFocus: false
  });

  useEffect(() => {
    if (query.data) {
      setUser(query.data);

      // Identify user in PostHog
      posthog.identify(query.data?.id?.toString(), {
        email: query.data?.email,
        name: query.data?.name
      });

      // Identify user in RevenueCat (only on native platforms)
      if (Capacitor.isNativePlatform() && query.data?.id) {
        try {
          // Log the user in with their unique ID
          Purchases.logIn({ appUserID: query.data.id.toString() })
            .then((customerInfo) => {
              console.log("RevenueCat user identified:", customerInfo);

              // Set user attributes in RevenueCat
              if (query.data) {
                const userAttributes: Record<string, string> = {};

                if (query.data.email) {
                  userAttributes.email = query.data.email;
                }

                if (query.data.name) {
                  userAttributes.name = query.data.name;
                }

                // Add any other relevant user attributes here
                // For example:
                if (query.data.createdAt) {
                  userAttributes.account_created_at = new Date(
                    query.data.createdAt
                  ).toISOString();
                }

                // Set the attributes in RevenueCat
                setUserAttributes(userAttributes).catch((error) => {
                  console.error(
                    "Error setting user attributes in RevenueCat:",
                    error
                  );
                });
              }
            })
            .catch((error) => {
              console.error("Error identifying user with RevenueCat:", error);
            });
        } catch (error) {
          console.error("Error with RevenueCat authentication:", error);
        }
      }
    }

    if (query.error) {
      if (axios.isAxiosError(query.error)) {
        const status = query.error.response?.status;
        if (status === 401 || status === 403) {
          clearUser();
          // Use the new removeAuthToken function
          removeAuthToken();

          // Reset PostHog user on logout
          posthog.reset();

          // Reset RevenueCat user on logout (only on native platforms)
          if (Capacitor.isNativePlatform()) {
            try {
              Purchases.logOut()
                .then(() => {
                  console.log("RevenueCat user logged out");
                })
                .catch((error) => {
                  console.error("Error logging out of RevenueCat:", error);
                });
            } catch (error) {
              console.error("Error with RevenueCat logout:", error);
            }
          }
        }
      }
    }
  }, [query.data, query.error, setUser, clearUser]);

  return { user: query.data, isLoading: query.isLoading || !isTokenLoaded };
}
