import Layout from "@/components/layout";
import { BookOpenText, Flame, Flower, HandHeart } from "lucide-react";
import { useState, useEffect } from "react";
import { updatePreferredGuide } from "@/utils/api";
import { useMutation } from "@tanstack/react-query";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";
import { useUserStore } from "@/stores/useUserStore";
import { getPath } from "@/utils/paths";

export const guides = [
  {
    id: 1,
    name: "Luna",
    icon: Flame,
    description:
      "Ancient wisdom meets straight talk. Luna cuts through confusion with insights and transformative truth.",
    image: getPath("/guides/swords.jpg"),
    slogan: "Truth, like a sword, cuts through confusion to reveal clarity"
  },
  {
    id: 2,
    name: "Sage",
    icon: HandHeart,
    description:
      "Where psychology meets spirituality. Sage weaves natural wisdom and celestial insights into gentle, profound readings.",
    image: getPath("/guides/cups.jpg"),
    slogan: "Every card holds a story that can speak to your heart"
  },
  {
    id: 3,
    name: "Thomas",
    icon: BookOpenText,
    description:
      "A literature professor turned Tarot master. Thomas connects archetypes, symbols, and mythology to illuminate your path.",
    image: getPath("/guides/wands.jpg"),
    slogan: "Where myths and symbols light the path to understanding"
  },
  {
    id: 4,
    name: "Maya",
    icon: Flower,
    description:
      "Your practical mystic. Maya transforms cosmic wisdom into real-world results for career, abundance, and personal growth.",
    image: getPath("/guides/pents.jpg"),
    slogan: "Where potential meets ground, possibilities can grow"
  }
];

const Guides = () => {
  const user = useUserStore((state) => state.user);
  const [selectedGuideId, setSelectedGuideId] = useState(
    user?.preferredGuideId || 1
  );
  const { toast } = useToast();

  useEffect(() => {
    if (user?.preferredGuideId) {
      setSelectedGuideId(user.preferredGuideId);
    }
  }, [user?.preferredGuideId]);

  const updateGuideMutation = useMutation({
    mutationFn: updatePreferredGuide,
    onSuccess: () => {
      toast({
        title: "Guide Updated",
        description: "Your preferred guide has been updated successfully.",
        duration: 3000
      });
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to update your preferred guide. Please try again.",
        variant: "destructive",
        duration: 3000
      });
    }
  });

  const handleGuideSelect = (guideId: number) => {
    setSelectedGuideId(guideId);
    updateGuideMutation.mutate(guideId);
  };

  return (
    <Layout>
      <h1 className="text-3xl text-center font-semibold text-primary-foreground my-6">
        Pick Your Guide
      </h1>
      <hr className="border-primary-foreground/20 mb-10" />

      <div className="flex flex-col gap-4 items-center w-full max-w-2xl mx-auto mb-8">
        {guides.map((guide) => (
          <div
            className={cn(
              "flex flex-col gap-3 p-6 border rounded-lg w-full transition-all duration-400 ease-in-out cursor-pointer relative overflow-hidden",
              selectedGuideId === guide.id
                ? "border-rose-600 bg-rose-50/30 shadow"
                : "border-primary-foreground/20 bg-rose-50/0 hover:border-rose-600/30"
            )}
            key={guide.id}
            onClick={() => handleGuideSelect(guide.id)}
          >
            <h3 className="text-2xl font-semibold text-primary-foreground">
              {guide.name}
            </h3>
            <p className="italic text-primary-foreground">
              {guide.description}
            </p>
            {/* <ul className="list-disc list-inside space-y-1">
              {guide.bulletPoints.map((point) => (
                <li
                  key={point}
                  className="leading-relaxed text-primary-foreground"
                >
                  {point}
                </li>
              ))}
            </ul> */}
            <guide.icon className="w-40 h-40 rotate-[20deg] text-rose-600 opacity-5 absolute right-10 top-14" />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Guides;
