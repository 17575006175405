// Get the public site base URL from environment or default to production path
const getPublicBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    return "http://localhost:3000";
  }
  return "";
};

// Helper to generate public site URLs
export const publicUrls = {
  learn: () => `${getPublicBaseUrl()}/learn`,
  home: () => `${getPublicBaseUrl()}/`
} as const;
