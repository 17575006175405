import { Capacitor } from "@capacitor/core";

export const getPlatform = () => {
  // First check if we're in a Capacitor build using environment variable
  const isCapacitorBuild = import.meta.env.VITE_BUILD_TARGET === "capacitor";

  const platform = {
    // Consider it native if Capacitor reports native OR we're explicitly building for Capacitor
    isNative: Capacitor.isNativePlatform() || isCapacitorBuild,
    isIOS: Capacitor.getPlatform() === "ios",
    isAndroid: Capacitor.getPlatform() === "android",
    isWeb: Capacitor.getPlatform() === "web" && !isCapacitorBuild
  };
  return platform;
};

export const isFirstTimeUser = async (): Promise<boolean> => {
  try {
    // Skip on web platform
    if (!Capacitor.isNativePlatform()) {
      return false;
    }

    // Import Preferences dynamically to prevent issues during SSR/build
    const { Preferences } = await import("@capacitor/preferences");

    const { value } = await Preferences.get({ key: "has_seen_onboarding" });

    // If no value exists, this is the first time
    if (!value) {
      // Set the flag for future checks
      await Preferences.set({ key: "has_seen_onboarding", value: "true" });
      return true;
    }

    return false;
  } catch (error) {
    console.error("Error checking first time user:", error);
    return false; // Default to false on error
  }
};

export const markOnboardingComplete = async (): Promise<void> => {
  try {
    if (!Capacitor.isNativePlatform()) return;

    const { Preferences } = await import("@capacitor/preferences");
    await Preferences.set({ key: "has_seen_onboarding", value: "true" });
  } catch (error) {
    console.error("Error marking onboarding complete:", error);
  }
};

// Add this function to reset the onboarding status for testing
export const resetOnboarding = async (): Promise<void> => {
  try {
    if (!Capacitor.isNativePlatform()) return;

    const { Preferences } = await import("@capacitor/preferences");
    await Preferences.remove({ key: "has_seen_onboarding" });
    console.log("Onboarding status reset successfully");
  } catch (error) {
    console.error("Error resetting onboarding status:", error);
  }
};

// Add functions to handle token storage for native platforms
export const storeAuthToken = async (token: string): Promise<void> => {
  try {
    if (Capacitor.isNativePlatform()) {
      const { Preferences } = await import("@capacitor/preferences");
      await Preferences.set({ key: "auth_token", value: token });
      console.log("Token stored in Capacitor Preferences");
    } else {
      // For web, use localStorage
      localStorage.setItem("token", token);
    }
  } catch (error) {
    console.error("Error storing auth token:", error);
  }
};

export const getAuthToken = async (): Promise<string | null> => {
  try {
    if (Capacitor.isNativePlatform()) {
      const { Preferences } = await import("@capacitor/preferences");
      const { value } = await Preferences.get({ key: "auth_token" });
      return value;
    } else {
      // For web, use localStorage
      return localStorage.getItem("token");
    }
  } catch (error) {
    console.error("Error retrieving auth token:", error);
    return null;
  }
};

export const removeAuthToken = async (): Promise<void> => {
  try {
    if (Capacitor.isNativePlatform()) {
      const { Preferences } = await import("@capacitor/preferences");
      await Preferences.remove({ key: "auth_token" });
    } else {
      // For web, use localStorage
      localStorage.removeItem("token");
    }
  } catch (error) {
    console.error("Error removing auth token:", error);
  }
};
