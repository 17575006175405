import React from "react";
import { Loader } from "lucide-react";
import { cn } from "@/lib/utils";

interface PushableButtonProps {
  text: string;
  onClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => void;
  size?: "small" | "normal";
  type?: "button" | "submit" | "reset";
  className?: string;
  disabled?: boolean;
  href?: string;
  isLoading?: boolean;
  target?: "_self" | "_blank" | "_parent" | "_top";
}

export const PushableButton: React.FC<PushableButtonProps> = ({
  text,
  onClick,
  size = "normal",
  type = "button",
  className = "",
  disabled = false,
  href,
  isLoading = false,
  target = "_self"
}) => {
  const baseClasses =
    "relative border-none bg-transparent p-0 cursor-pointer outline-offset-4 select-none touch-manipulation transition-[filter] duration-250 hover:brightness-110";
  const shadowClasses =
    "absolute top-0 left-0 w-full h-full bg-black/25  transition-transform duration-600 ease-[cubic-bezier(.3,.7,.4,1)]";
  const edgeClasses =
    "absolute top-0 left-0 w-full h-full bg-gradient-to-l from-rose-900 via-rose-800 to-rose-900";
  const frontClasses =
    "flex items-center justify-center block relative text-white bg-rose-600 font-semibold cursor-pointer  transition-transform duration-600 ease-[cubic-bezier(.3,.7,.4,1)]";

  const sizeClasses = {
    normal: {
      button: "",
      shadow:
        "rounded-xl translate-y-0.5 group-hover:translate-y-1 group-active:translate-y-[0.25px]",
      edge: "rounded-xl",
      front:
        "py-3 px-7 md:px-10 rounded-xl text-lg md:text-xl -translate-y-1 group-hover:-translate-y-1.5 group-active:-translate-y-0.5"
    },
    small: {
      button: "scale-75 md:scale-90",
      shadow:
        "rounded-lg translate-y-0.5 group-hover:translate-y-1 group-active:translate-y-[0.25px]",
      edge: "rounded-lg",
      front:
        "py-2 px-5 md:px-7 rounded-lg text-base md:text-lg -translate-y-1 group-hover:-translate-y-1.5 group-active:-translate-y-0.5"
    }
  };

  const ButtonContent = () => (
    <>
      <span className={cn(shadowClasses, sizeClasses[size].shadow)} />
      <span className={cn(edgeClasses, sizeClasses[size].edge)} />
      <span className={cn(frontClasses, sizeClasses[size].front, "flex")}>
        <span>{text}</span>
        {isLoading && (
          <span className="ml-2">
            <Loader className="w-5 h-5 animate-spin" />
          </span>
        )}
      </span>
    </>
  );

  if (href) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        className={cn(
          "inline-block group",
          baseClasses,
          sizeClasses[size].button,
          className
        )}
        onClick={onClick}
        target={target}
      >
        <ButtonContent />
      </a>
    );
  }

  return (
    <button
      className={cn("group", baseClasses, sizeClasses[size].button, className)}
      role="button"
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      <ButtonContent />
    </button>
  );
};
