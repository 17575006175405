import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FlashcardWithProgress, FlashcardRating } from "../../types/flashcards";
import { flashcardsApi } from "../../utils/api";
import { useAuth } from "../../hooks/useAuth";
import { getPath } from "../../utils/paths";
import Layout from "../layout";
import { useBreadcrumbStore } from "@/stores/breadcrumb";

const FlashcardStudy: React.FC = () => {
  const { moduleId } = useParams<{ moduleId: string }>();
  const [flashcards, setFlashcards] = useState<FlashcardWithProgress[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isComplete, setIsComplete] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();
  const { setDynamicSegment } = useBreadcrumbStore();

  useEffect(() => {
    const fetchFlashcards = async () => {
      try {
        setLoading(true);
        const flashcardsData = await flashcardsApi.getStudyFlashcards(
          moduleId ? parseInt(moduleId) : undefined
        );

        const module = await flashcardsApi.getModule(
          moduleId ? parseInt(moduleId) : undefined
        );

        if (module) {
          setDynamicSegment(`/learn/study/${moduleId}`, module.name);
        }

        setFlashcards(flashcardsData);

        if (flashcardsData.length === 0) {
          setIsComplete(true);
        }
      } catch (err) {
        console.error("Error fetching flashcards:", err);
        setError("Failed to load flashcards. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchFlashcards();
  }, [moduleId, user]);

  const handleShowAnswer = () => {
    setShowAnswer(true);
  };

  const handleRateCard = async (rating: FlashcardRating) => {
    if (!user || flashcards.length === 0) return;

    const currentFlashcard = flashcards[currentIndex];

    try {
      // Rate the flashcard
      await flashcardsApi.rateFlashcard(currentFlashcard.id, rating);

      // Move to the next card or finish if this was the last one
      if (currentIndex < flashcards.length - 1) {
        setCurrentIndex(currentIndex + 1);
        setShowAnswer(false);
      } else {
        setIsComplete(true);
      }
    } catch (err) {
      console.error("Error rating flashcard:", err);
      setError("Failed to save your rating. Please try again.");
    }
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64">
          Loading flashcards...
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="text-red-500 text-center p-4">{error}</div>
      </Layout>
    );
  }

  if (isComplete) {
    return (
      <Layout>
        <div className="container mx-auto px-4 py-8 max-w-2xl">
          <div className="text-center p-8 bg-card rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4 text-card-foreground">
              Study Session Complete!
            </h2>
            <p className="mb-6 text-muted-foreground">
              You've completed all the flashcards for this session. Great job!
            </p>
            <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 justify-center">
              <Link
                to={getPath("/learn")}
                className="bg-primary hover:bg-primary/90 text-primary-foreground px-6 py-2 rounded-md"
              >
                Back to Modules
              </Link>
              <button
                onClick={() => {
                  setFlashcards([]);
                  setCurrentIndex(0);
                  setShowAnswer(false);
                  setIsComplete(false);
                  setLoading(true);
                  navigate(0); // Refresh the page to start a new session
                }}
                className="border border-primary text-primary hover:bg-primary/10 px-6 py-2 rounded-md"
              >
                Start New Session
              </button>
            </div>
          </div>
        </div>
      </Layout>
    );
  }

  if (flashcards.length === 0) {
    return (
      <Layout>
        <div className="container mx-auto px-4 py-8 max-w-2xl">
          <div className="text-center p-8 bg-card rounded-lg shadow-md">
            <h2 className="text-2xl font-bold mb-4 text-card-foreground">
              No Flashcards Available
            </h2>
            <p className="mb-6 text-muted-foreground">
              There are no flashcards due for review at this time. Check back
              later!
            </p>
            <Link
              to={getPath("/learn")}
              className="bg-primary hover:bg-primary/90 text-primary-foreground px-6 py-2 rounded-md"
            >
              Back to Modules
            </Link>
          </div>
        </div>
      </Layout>
    );
  }

  const currentFlashcard = flashcards[currentIndex];
  const progress = Math.round(((currentIndex + 1) / flashcards.length) * 100);

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8 max-w-2xl">
        <div className="mb-6 flex justify-between items-center">
          <Link
            to={getPath("/learn")}
            className="text-primary hover:text-primary/80"
          >
            &larr; Back to Modules
          </Link>
          <div className="text-sm text-muted-foreground">
            Card {currentIndex + 1} of {flashcards.length}
          </div>
        </div>

        {/* Progress bar */}
        <div className="w-full bg-muted rounded-full h-2.5 mb-6">
          <div
            className="bg-green-500 h-2.5 rounded-full"
            style={{ width: `${progress}%` }}
          ></div>
        </div>

        <div className="bg-primary rounded-lg shadow-md overflow-hidden">
          {/* Card type and related info */}
          {/* <div className="bg-muted/50 px-6 py-3 flex justify-between items-center"> */}
          {/* <span className="text-sm text-muted-foreground capitalize">
              {currentFlashcard.type}
            </span> */}
          {/* <div className="flex items-center gap-2">
              {currentFlashcard.relatedCardId && (
                <span className="text-sm text-primary">Card-specific</span>
              )}
              {currentFlashcard.progress && (
                <span className="text-xs text-muted-foreground">
                  Reviews: {currentFlashcard.progress.repetitions}
                  {currentFlashcard.progress.nextReview && (
                    <>
                      {" "}
                      | Next review:{" "}
                      {new Date(
                        currentFlashcard.progress.nextReview
                      ).toLocaleDateString()}
                    </>
                  )}
                </span>
              )}
            </div> */}
          {/* </div> */}

          {/* Question */}
          <div className="px-6 py-8 border-b border-primary-foreground-muted/20">
            <h2 className="text-2xl text-primary-foreground text-center mt-4">
              {currentFlashcard.question}
            </h2>
          </div>

          {/* Answer (hidden until revealed) */}
          {showAnswer ? (
            <div className="px-6 py-8 bg-primary/5">
              <p className="text-primary-foreground text-center text-lg">
                {currentFlashcard.answer}
              </p>

              {/* Rating buttons */}
              <div className="mt-8 flex flex-col sm:flex-row justify-center gap-3">
                <button
                  onClick={() => handleRateCard("again")}
                  className="bg-red-500 hover:bg-red-600 text-white px-6 py-3 rounded-md"
                >
                  Again
                  <span className="block text-xs mt-1">Forgot</span>
                  <span className="block text-xs mt-1">(1 day)</span>
                </button>
                <button
                  onClick={() => handleRateCard("good")}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-3 rounded-md"
                >
                  Good
                  <span className="block text-xs mt-1">Remembered</span>
                  <span className="block text-xs mt-1">(1-3 days)</span>
                </button>
                <button
                  onClick={() => handleRateCard("easy")}
                  className="bg-emerald-500 hover:bg-emerald-600 text-white px-6 py-3 rounded-md"
                >
                  Easy
                  <span className="block text-xs mt-1">Too Easy</span>
                  <span className="block text-xs mt-1">(2-5 days)</span>
                </button>
              </div>

              {/* Display related card if available */}
              {currentFlashcard.relatedCard && (
                <div className="mt-6 flex flex-col items-center">
                  <img
                    src={currentFlashcard.relatedCard.imageUrl || ""}
                    alt={currentFlashcard.relatedCard.name}
                    className="w-48 border-4 border-primary-foreground/20 h-auto rounded-md shadow-md"
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="px-6 py-8 text-center">
              <button
                onClick={handleShowAnswer}
                className="bg-primary hover:bg-primary/90 text-primary-foreground px-6 py-3 rounded-md"
              >
                Show Answer
              </button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default FlashcardStudy;
