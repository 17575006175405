import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  checkIfDailyReadingExists,
  createReading,
  fetchTarotCards
} from "@/utils/api";
import { queryClient } from "@/utils/queryClient";
import Layout from "@/components/layout";
import { motion, AnimatePresence } from "framer-motion";
import type { Card as CardType, CardWithReversed } from "@/types/Card";
import { cn } from "@/lib/utils";
import cardBack from "/card-back.jpg";
import { PushableButton } from "@/components/PushableButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@/hooks/useWindowSize";
import { Reading } from "@/types/api";
import { toast } from "@/components/ui/use-toast";
import { CardLoader } from "@/components/CardLoader";
import { CardSelectionDialog } from "@/components/CardSelectionDialog";
import { Textures } from "@/components/Textures";
import { getPlatform } from "@/utils/platform";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { getPath } from "@/utils/paths";

const useHoverState = () => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [randomLift] = useState(() => 50 + Math.random() * 40); // Random lift between 30-45px

  return {
    hoveredIndex,
    setHoveredIndex,
    randomLift
  };
};

const DailyDraw: React.FC = () => {
  const { hoveredIndex, setHoveredIndex, randomLift } = useHoverState();
  const [searchParams] = useSearchParams();
  const [showOnboardingModal, setShowOnboardingModal] = useState(false);

  const {
    data: tarotCards,
    isLoading: isTarotCardsLoading,
    isFetching: isTarotCardsFetching
  } = useQuery({
    queryKey: ["tarotCards"],
    queryFn: fetchTarotCards
  });

  const {
    data: dailyReading,
    isLoading: isDailyReadingLoading,
    isFetching: isDailyReadingFetching
  } = useQuery<Reading | null, Error>({
    queryKey: ["dailyReading"],
    queryFn: checkIfDailyReadingExists,
    staleTime: 0, // 0 seconds
    gcTime: 1000 * 60 * 60, // 1 hour
    refetchOnMount: "always"
  });

  const { isNative } = getPlatform();
  const { width: windowWidth } = useWindowSize();
  const [cards, setCards] = useState<CardWithReversed[]>([]);
  const [draggedCards, setDraggedCards] = useState<number[]>([]);
  const [isDoneDragging, setIsDoneDragging] = useState(false);
  const [isInitialAnimationComplete, setIsInitialAnimationComplete] =
    useState(false);
  const [isRevealed, setIsRevealed] = useState(false);
  const [readingId, setReadingId] = useState<string | null>(null);
  const [isCardSelectionOpen, setIsCardSelectionOpen] = useState(false);
  const hoverState = { hoveredIndex, setHoveredIndex, randomLift };
  const dailyDrawContainerRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const createReadingMutation = useMutation({
    mutationFn: createReading,
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ["dailyReading"] });
      await queryClient.refetchQueries({ queryKey: ["readings"] });
    }
  });

  useEffect(() => {
    if (tarotCards) {
      const shuffledCards = shuffleArray(tarotCards);
      const cardsWithReversed = shuffledCards.map((card) => ({
        ...card,
        isReversed: Math.random() < 1 / 3 // One third chance of being reversed
      }));

      // If there's a daily reading, update the isReversed property of the drawn card
      if (dailyReading?.card) {
        const drawnCardIndex = cardsWithReversed.findIndex(
          (card) => card.id === dailyReading.card.id
        );
        if (drawnCardIndex !== -1) {
          cardsWithReversed[drawnCardIndex].isReversed =
            dailyReading.isReversed;
        }
      }

      setCards(cardsWithReversed);
    }
  }, [tarotCards, dailyReading]);

  const shuffleArray = (array: CardType[]): CardType[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleDragEnd = async (cardId: number) => {
    setDraggedCards((prevDraggedCards) => {
      if (!prevDraggedCards.includes(cardId)) {
        return [...prevDraggedCards, cardId];
      }
      return prevDraggedCards;
    });
    setIsDoneDragging(true);
  };

  const handleCardReveal = async (cardId: number, isReversed: boolean) => {
    try {
      const result = await createReadingMutation.mutateAsync({
        cardId,
        isReversed
      });
      setReadingId(result.readingId);
    } catch (error) {
      console.error("Error revealing card:", error);
      toast({
        title: "Error",
        description: "Failed to create reading. Please try again.",
        variant: "destructive"
      });
    }
  };

  const handleGetInterpretation = () => {
    if (readingId) {
      navigate(`/readings/${readingId}`);
    } else {
      console.error("No reading ID available");
      // TODO: Handle error (e.g., show error message to user)
    }
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
        staggerChildren: 1
      }
    }
  };

  useEffect(() => {
    if (dailyReading) {
      // If a reading exists, set up the state as if that card was drawn
      const drawnCard = dailyReading.card;
      if (drawnCard) {
        setDraggedCards([drawnCard.id]);
        setIsDoneDragging(true);
        setIsRevealed(true);
        setReadingId(dailyReading.id.toString());
      }
    }
  }, [dailyReading]);

  const handleAddCard = () => {
    setIsCardSelectionOpen(true);
  };

  const handleCardSelect = (cardId: number, isReversed: boolean) => {
    handleDragEnd(cardId);
    setIsRevealed(true);
    handleCardReveal(cardId, isReversed);
  };

  // Calculate available height for the daily draw container
  useEffect(() => {
    if (isNative) {
      const calculateAvailableHeight = () => {
        try {
          // Force the layout container to take up the full viewport height
          const layoutElement = document.querySelector(
            'div[id="main-content"]'
          )?.parentElement;
          if (layoutElement) {
            layoutElement.style.height = "100vh";
            layoutElement.style.maxHeight = "100vh";
            layoutElement.style.overflow = "hidden";
          }

          // Force the main-content to take up the full height minus the header
          const mainContent = document.getElementById("main-content");
          if (mainContent) {
            mainContent.style.height =
              "calc(100vh - var(--header-height, 48px))";
            mainContent.style.maxHeight =
              "calc(100vh - var(--header-height, 48px))";
            mainContent.style.overflow = "hidden";
            mainContent.style.paddingBottom = "0";
          }

          // Set the daily draw container to take up the full height of its parent
          if (dailyDrawContainerRef.current) {
            dailyDrawContainerRef.current.style.height = "100%";
            dailyDrawContainerRef.current.style.maxHeight = "100%";
            dailyDrawContainerRef.current.style.overflow = "hidden";
          }

          // Prevent body scrolling
          document.body.style.overflow = "hidden";
          document.documentElement.style.overflow = "hidden";

          // Store the header height as a CSS variable for future calculations
          const mainHeader = document.getElementById("main-header");
          if (mainHeader) {
            const headerHeight = mainHeader.offsetHeight;
            document.documentElement.style.setProperty(
              "--header-height",
              `${headerHeight}px`
            );
          }
        } catch (error) {
          console.error("Error setting container heights:", error);
        }
      };

      // Calculate immediately and after a short delay
      calculateAvailableHeight();
      const timer = setTimeout(calculateAvailableHeight, 300);

      // Recalculate on resize and orientation change
      window.addEventListener("resize", calculateAvailableHeight);
      window.addEventListener("orientationchange", calculateAvailableHeight);

      return () => {
        window.removeEventListener("resize", calculateAvailableHeight);
        window.removeEventListener(
          "orientationchange",
          calculateAvailableHeight
        );
        clearTimeout(timer);

        // Reset styles
        const layoutElement = document.querySelector(
          'div[id="main-content"]'
        )?.parentElement;
        if (layoutElement) {
          layoutElement.style.height = "";
          layoutElement.style.maxHeight = "";
          layoutElement.style.overflow = "";
        }

        const mainContent = document.getElementById("main-content");
        if (mainContent) {
          mainContent.style.height = "";
          mainContent.style.maxHeight = "";
          mainContent.style.overflow = "";
          mainContent.style.paddingBottom = "";
        }

        if (dailyDrawContainerRef.current) {
          dailyDrawContainerRef.current.style.height = "";
          dailyDrawContainerRef.current.style.maxHeight = "";
          dailyDrawContainerRef.current.style.overflow = "";
        }

        document.body.style.overflow = "";
        document.documentElement.style.overflow = "";
        document.documentElement.style.removeProperty("--header-height");
      };
    }
  }, [isNative]);

  // Check for showOnboarding parameter in URL
  useEffect(() => {
    const showOnboarding = searchParams.get("showOnboarding");
    if (showOnboarding === "true") {
      setShowOnboardingModal(true);
      // Remove the parameter from the URL without refreshing the page
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("showOnboarding");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}${
          newSearchParams.toString() ? `?${newSearchParams.toString()}` : ""
        }`
      );
    }
  }, [searchParams]);

  if (
    (isTarotCardsLoading && !isTarotCardsFetching) ||
    (isDailyReadingLoading && !isDailyReadingFetching)
  ) {
    return (
      <Layout>
        <CardLoader loadingText="Loading your daily draw..." />
      </Layout>
    );
  }

  return (
    <>
      {/* Onboarding Modal */}
      <Dialog open={showOnboardingModal} onOpenChange={setShowOnboardingModal}>
        <DialogContent className="sm:max-w-md font-rosarivo text-primary-foreground">
          <div className="space-y-4">
            <h2 className="text-xl mt-2">Learn Tarot Your Way</h2>
            <p>
              Master tarot through our unique combination of structured learning
              and hands-on practice. Our spaced repetition system helps you
              build genuine understanding at your own pace.
            </p>

            <h2 className="text-xl mt-2">Draw Your Daily Card</h2>
            <p>
              Each day brings a new opportunity for insight and learning. Draw
              from our digital deck, or add your own card if you're using a
              physical deck.
            </p>

            <h2 className="text-xl mt-2">Personal Interpretation</h2>
            <p>
              Take a moment to connect with the card's imagery and meaning
              before revealing the guide's reading. Apply what you've learned
              from your studies.
            </p>

            <h2 className="text-xl mt-2">Grow with Your Guide</h2>
            <p>
              Get thoughtful interpretations and chat about how the card
              connects to your current life. Each reading reinforces your
              learning journey and deepens your understanding of tarot's
              symbolic language.
            </p>
          </div>
          <div className="flex justify-center">
            <PushableButton
              text="Begin Your Journey"
              onClick={() => setShowOnboardingModal(false)}
            />
          </div>
        </DialogContent>
      </Dialog>

      <Layout disablePadding>
        <div
          ref={dailyDrawContainerRef}
          className={cn("relative rounded-xl overflow-hidden", {
            "h-full": !isNative
          })}
          id="daily-draw-container"
        >
          <h1
            className={cn(
              { "landscape:hidden": isNative },
              "absolute z-40 w-full portrait:text-2xl landscape:text-lg md:text-3xl text-center font-bold mb-6 text-orange-950 pointer-events-none"
            )}
          >
            Daily Draw
          </h1>

          <div className="absolute z-30 inset-0 flex justify-center items-center pointer-events-none">
            {/* Centered Card Outline - Adjust vertical position */}
            <div className="absolute inset-0 flex items-center justify-center">
              <button
                type="button"
                className={cn(
                  "aspect-[400/700] flex border-2 border-dashed border-primary-foreground/20 bg-background-dark/10 rounded-lg items-center justify-center transform -translate-y-[10%]",
                  isNative
                    ? "landscape:w-[100px] w-[150px] -translate-y-[30%]"
                    : "w-[150px] md:w-[300px]",
                  {
                    "hover:bg-background-dark/50 cursor-pointer pointer-events-auto":
                      !isRevealed
                  }
                )}
                onClick={() => {
                  if (!isRevealed) {
                    handleAddCard();
                  }
                }}
              >
                <span className="text-orange-950 text-center uppercase font-sans text-xs text-primary-foreground/50 flex-col flex">
                  <span>Drag Card</span>
                  <span>Or</span>
                  <span>
                    Click to <br /> Add Card
                  </span>
                </span>
              </button>
            </div>

            {/* Update the button positioning */}
            {isRevealed && (
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5, delay: 1.5 }}
                  className={cn(
                    "absolute left-1/2 transform -translate-x-1/2 pointer-events-auto z-50",
                    // On mobile, stick to bottom of screen
                    isNative
                      ? "landscape:bottom-4 bottom-20"
                      : "bottom-8 sm:bottom-12"
                    // // On medium screens and up, position relative to card
                    // // "md:bottom-auto",
                    // // Dynamic positioning based on screen size
                    // windowWidth === 768 && height === 1024
                    //   ? "top-[75%]" // iPad Mini portrait
                    //   : "md:top-[60%]" // All other devices
                  )}
                >
                  <PushableButton
                    text="Go To Reading"
                    size={windowWidth < 640 || isNative ? "small" : "normal"}
                    onClick={handleGetInterpretation}
                  />
                </motion.div>
              </AnimatePresence>
            )}

            {cards.length > 0 && (
              <AnimatePresence>
                <motion.div
                  className="pointer-events-none absolute inset-0 flex items-center justify-center"
                  variants={containerVariants}
                >
                  {cards.map((card, index) => (
                    <Card
                      key={card.id}
                      card={card}
                      index={index}
                      total={cards.length}
                      onDragEnd={handleDragEnd}
                      isDoneDragging={isDoneDragging}
                      draggedCards={draggedCards}
                      isRevealed={isRevealed}
                      setIsRevealed={setIsRevealed}
                      onReveal={handleCardReveal}
                      hoverState={hoverState}
                      isInitialAnimationComplete={isInitialAnimationComplete}
                      setIsInitialAnimationComplete={
                        setIsInitialAnimationComplete
                      }
                    />
                  ))}
                </motion.div>
              </AnimatePresence>
            )}
          </div>
        </div>
        <CardSelectionDialog
          open={isCardSelectionOpen}
          onOpenChange={setIsCardSelectionOpen}
          cards={cards}
          onCardSelect={handleCardSelect}
        />
      </Layout>
    </>
  );
};

interface CardProps {
  card: CardWithReversed;
  index: number;
  total: number;
  onDragEnd: (cardId: number) => void;
  isDoneDragging: boolean;
  draggedCards: number[];
  isRevealed: boolean;
  setIsRevealed: (isRevealed: boolean) => void;
  onReveal: (cardId: number, isReversed: boolean) => void;
  hoverState: {
    hoveredIndex: number | null;
    setHoveredIndex: (index: number | null) => void;
    randomLift: number;
  };
  isInitialAnimationComplete: boolean;
  setIsInitialAnimationComplete: (isInitialAnimationComplete: boolean) => void;
}

const Card: React.FC<CardProps> = ({
  card,
  index,
  total,
  onDragEnd,
  isDoneDragging,
  draggedCards,
  isRevealed,
  setIsRevealed,
  onReveal,
  hoverState,
  isInitialAnimationComplete,
  setIsInitialAnimationComplete
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const isDragged = draggedCards.includes(card.id);
  const cardRef = useRef<HTMLDivElement>(null);
  const { x, y, angle } = useCardPosition(index, total);
  const draggedPosition = useDraggedPosition();
  const cardStyles = useCardStyles(isDoneDragging, isDragged);
  const { isNative } = getPlatform();
  const scale = draggedPosition.scale;

  const cardAnimations = useCardAnimations(
    isDragged,
    isRevealed,
    x,
    y,
    angle,
    index,
    hoverState,
    isInitialAnimationComplete,
    setIsInitialAnimationComplete,
    card.isReversed
  );

  // Add parallax effect
  useEffect(() => {
    if (isDragged && isRevealed) {
      const handleMouseMove = (e: MouseEvent) => {
        if (cardRef.current) {
          const rect = cardRef.current.getBoundingClientRect();
          const cardCenterX = rect.left + rect.width / 2;
          const cardCenterY = rect.top + rect.height / 2;

          const mouseX = (e.clientX - cardCenterX) / (rect.width / 2);
          const mouseY = (e.clientY - cardCenterY) / (rect.height / 2);

          const tiltX = -mouseY * 0.5;
          const tiltY = -mouseX * 1.5;

          // Add a slight upward translation to center the card better
          const verticalOffset = "-5%";

          // Add the 180-degree rotation if the card is reversed
          const reversedRotation = card.isReversed ? 180 : 0;

          cardRef.current.style.transform = `
            perspective(1000px)
            rotateX(${tiltX}deg)
            rotateY(${tiltY}deg)
            translateY(${draggedPosition.y}) translateY(${verticalOffset})
            rotate(${reversedRotation}deg)
            scale(${scale})
          `;
        }
      };

      window.addEventListener("mousemove", handleMouseMove);

      return () => {
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [isDragged, isRevealed, scale, draggedPosition, card.isReversed]);

  const handleCardClick = async () => {
    if (isDragged && !isRevealed) {
      setIsRevealed(true);
      await onReveal(card.id, card.isReversed);
    }
  };

  return (
    <motion.div
      ref={cardRef}
      className={cn(
        "absolute overflow-hidden aspect-[375/629] rounded-lg lg:rounded-2xl p-3 pb-[46px] bg-[#f4e7d8] border border-black border-opacity-40 pointer-events-auto",
        isNative
          ? "landscape:w-[120px] w-[180px] top-3/4 landscape:top-[50%]"
          : // ? "landscape:w-[120px] portrait:w-[150px] sm:w-[180px] sm:top-3/4 landscape:top-[50%] portrait:top-[20%]"
            "w-[180px] md:w-[200px] top-3/4",
        {
          "cursor-grabbing": isDragging && !isRevealed,
          "cursor-grab": !isDragging && !isRevealed,
          "m-0 z-10": isDragged
        }
      )}
      style={{
        ...cardStyles,
        transformStyle: "preserve-3d",
        transition:
          isDragged && isRevealed ? "transform 0.1s ease-out" : undefined
      }}
      {...cardAnimations}
      drag={(!isDragged && draggedCards.length === 0) || !isDoneDragging}
      dragConstraints={{
        top: -window.innerHeight / 2,
        bottom: 0,
        left: -window.innerWidth / 2,
        right: window.innerWidth / 2
      }}
      dragElastic={0.4}
      dragTransition={{
        bounceStiffness: 200,
        bounceDamping: 15,
        power: 0.2,
        timeConstant: 200,
        restDelta: 0.5,
        min: -800,
        max: 800
      }}
      whileDrag={{
        scale: 1.02,
        rotate: isDragging ? [-0.5, 0.5] : 0,
        transition: {
          rotate: {
            repeat: Infinity,
            repeatType: "mirror",
            duration: 0.3
          }
        }
      }}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={(_, info) =>
        handleDragEnd(info, isDragged, card.id, onDragEnd, setIsDragging)
      }
      onClick={handleCardClick}
    >
      <div
        className="relative w-full h-full z-10"
        style={{ transformStyle: "preserve-3d" }}
      >
        <CardNumber
          isRevealed={isRevealed}
          cardNumber={card.cardNumber}
          isDragged={isDragged}
          arcanaType={card.arcanaType}
        />
        <CardBack isDragged={isDragged} isRevealed={isRevealed} />
        <CardFront card={card} isDragged={isDragged} isRevealed={isRevealed} />
      </div>
    </motion.div>
  );
};

const useCardPosition = (index: number, total: number) => {
  const angle = (index / (total - 1)) * 90 - 45; // -45 to 45 degrees
  const radius = 800;
  const scaleY = 0.5;
  const x = Math.sin((angle * Math.PI) / 180) * radius;
  const topYMargin = 50;
  const y =
    -Math.cos((angle * Math.PI) / 180) * radius * scaleY +
    radius * scaleY +
    topYMargin;
  return { x, y, angle };
};

const useDraggedPosition = () => {
  const { width, height } = useWindowSize();
  const scale = getResponsiveScale(width);

  // Calculate vertical center position dynamically based on viewport height
  const centerY = height * 0.45; // Position at 45% from the top (slightly above center)
  const responsiveOffset = height * 0.05; // 5% of viewport height as offset
  const baseOffset = Math.min(100, height * 0.1); // Responsive base offset, max 100px

  return {
    x: 0,
    y: `calc(-${centerY}px + ${baseOffset}px - ${responsiveOffset}px)`,
    scale: scale,
    rotate: 0
  };
};

const useCardStyles = (isDoneDragging: boolean, isDragged: boolean) => ({
  transformStyle: "preserve-3d" as const,
  transition: "transform 0.3s ease-out",
  ...(isDoneDragging && isDragged ? { margin: 0 } : {})
});

/**
 * This function is used to animate the card.
 * It is used to animate the card when it is dragged, revealed, and hovered.
 * It is also used to animate the card when it is initialised.
 */
const useCardAnimations = (
  isDragged: boolean,
  isRevealed: boolean,
  x: number,
  y: number,
  angle: number,
  index: number,
  hoverState: CardProps["hoverState"],
  isInitialAnimationComplete: boolean,
  setIsInitialAnimationComplete: (isInitialAnimationComplete: boolean) => void,
  isReversed: boolean
) => {
  const draggedPosition = useDraggedPosition();
  const { hoveredIndex, randomLift } = hoverState;

  // Add useEffect to detect when initial animation is complete
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsInitialAnimationComplete(true);
    }, 600 + index * 0.02 * 1000);

    return () => clearTimeout(timer);
  }, []);

  const getNeighborLift = (distance: number) => {
    const maxNeighborDistance = 2; // How many cards to the left and right to affect
    const baseNeighborLift = randomLift * 0.5; // Base lift amount for neighbors (50% of main lift)

    if (distance > maxNeighborDistance) return 0;

    return baseNeighborLift * (1 - distance / (maxNeighborDistance + 1));
  };

  const calculateLift = () => {
    if (hoveredIndex === null) return 0;

    if (hoveredIndex === index) {
      return randomLift;
    }

    const distance = Math.abs(hoveredIndex - index);
    return getNeighborLift(distance);
  };

  const calculateRotation = () => {
    // Add 180 degrees when card is revealed and reversed
    const reversedRotation = isDragged && isRevealed && isReversed ? 180 : 0;
    return angle + reversedRotation;
  };

  const getHoverTransition = () => ({
    duration: isInitialAnimationComplete ? 0.2 : 0.6,
    ease: "easeOut",
    delay: 0
  });

  return {
    initial: isDragged
      ? draggedPosition
      : {
          y: y + 200 + 10 * index,
          rotate: calculateRotation(),
          rotateY: 180,
          opacity: 1
        },
    animate: isDragged
      ? {
          ...draggedPosition,
          rotateY: isRevealed ? 0 : 180,
          translateZ: isRevealed ? -100 : [2, 180, 2],
          rotateX: 5,
          transition: {
            type: "spring",
            stiffness: 200,
            damping: 20
          }
        }
      : {
          x,
          y: y - calculateLift(),
          rotateY: 180,
          opacity: 1,
          rotate: calculateRotation(),
          transition: hoverState !== null ? getHoverTransition() : undefined
        },
    whileHover:
      !isDragged && !isRevealed
        ? {
            x: x - 20,
            y: y - calculateLift(),
            rotate: calculateRotation(),
            transition: getHoverTransition()
          }
        : undefined,
    transition: {
      default: {
        type: "spring",
        stiffness: 300,
        damping: 25,
        duration: isInitialAnimationComplete ? 0.2 : 0.6,
        delay: isDragged ? 0 : isInitialAnimationComplete ? 0 : 1 + 0.02 * index
      },
      rotate: {
        duration: isInitialAnimationComplete ? 0.2 : 0.3,
        ease: "easeOut"
      }
    },
    onHoverStart: () => {
      if (!isDragged && !isRevealed) {
        hoverState.setHoveredIndex(index);
      }
    },
    onHoverEnd: () => {
      if (!isDragged && !isRevealed) {
        hoverState.setHoveredIndex(null);
      }
    }
  };
};

const handleDragEnd = (
  info: any,
  isDragged: boolean,
  cardId: number,
  onDragEnd: (cardId: number) => void,
  setIsDragging: (isDragging: boolean) => void
) => {
  // Get the drag distance from the info object
  const dragDistance = info.offset.y;
  const dragThreshold = -50; // Adjust this value as needed

  if (
    !isDragged &&
    // Check for mouse position (desktop)
    (info.point.y < window.innerHeight - 200 ||
      // Check for drag distance (mobile)
      dragDistance < dragThreshold)
  ) {
    onDragEnd(cardId);
    setIsDragging(false);
  }
};

// Subcomponents for CardContent
const CardNumber: React.FC<{
  cardNumber: number;
  isDragged: boolean;
  isRevealed: boolean;
  arcanaType: string;
}> = ({ cardNumber, isDragged, isRevealed, arcanaType }) => {
  // Don't render anything for Minor Arcana cards
  if (arcanaType !== "Major Arcana") {
    return null;
  }

  // Convert numbers to Roman numerals
  const numberToRomanNumeral = (num: number): string => {
    const romanNumerals = [
      { value: 22, numeral: "XXII" },
      { value: 21, numeral: "XXI" },
      { value: 20, numeral: "XX" },
      { value: 19, numeral: "XIX" },
      { value: 18, numeral: "XVIII" },
      { value: 17, numeral: "XVII" },
      { value: 16, numeral: "XVI" },
      { value: 15, numeral: "XV" },
      { value: 14, numeral: "XIV" },
      { value: 13, numeral: "XIII" },
      { value: 12, numeral: "XII" },
      { value: 11, numeral: "XI" },
      { value: 10, numeral: "X" },
      { value: 9, numeral: "IX" },
      { value: 8, numeral: "VIII" },
      { value: 7, numeral: "VII" },
      { value: 6, numeral: "VI" },
      { value: 5, numeral: "V" },
      { value: 4, numeral: "IV" },
      { value: 3, numeral: "III" },
      { value: 2, numeral: "II" },
      { value: 1, numeral: "I" },
      { value: 0, numeral: "0" }
    ];

    const found = romanNumerals.find((r) => r.value === num);
    return found ? found.numeral : num.toString();
  };

  return (
    <motion.div
      animate={{
        opacity: isDragged && isRevealed ? 1 : 0,
        transition: { delay: 0.15 }
      }}
      className="flex items-center absolute w-full justify-center z-50 top-0"
    >
      <span className="z-10 top-2 relative text-sm">
        {numberToRomanNumeral(cardNumber)}
      </span>
    </motion.div>
  );
};

const CardBack: React.FC<{ isDragged: boolean; isRevealed: boolean }> = ({
  isDragged,
  isRevealed
}) => (
  <motion.div
    className="absolute w-full h-[calc(100%+50px-1rem)] backface-hidden pointer-events-none border border-black border-opacity-70 rounded-md"
    style={{ backfaceVisibility: "hidden" }}
    animate={{ opacity: isDragged && isRevealed ? 0 : 1 }}
  >
    <img
      src={cardBack}
      className="object-cover w-full h-full pointer-events-none rounded-md"
      alt="Card Back"
    />
  </motion.div>
);

const CardFront: React.FC<{
  card: CardWithReversed;
  isDragged: boolean;
  isRevealed: boolean;
}> = ({ card, isDragged, isRevealed }) => {
  // Get mouse position and percentage of the screen
  // Don't use any libraries for this, just plain JS
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const { width } = useWindowSize();
  const scale = getResponsiveScale(width);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      // Calculate the percentage of the screen
      const percentageX = e.clientX / window.innerWidth;
      const percentageY = e.clientY / window.innerHeight;

      setOffset({ x: percentageX, y: percentageY });
    };

    if (isDragged) window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [isDragged]);

  return (
    <motion.div
      className="absolute w-full h-full backface-hidden pointer-events-none"
      style={{ backfaceVisibility: "hidden" }}
      animate={{ opacity: isDragged && isRevealed ? 1 : 0 }}
      transition={{ duration: 0.1, delay: isDragged ? 0.2 : 0 }}
    >
      <div className="overflow-hidden relative w-full h-full rounded-t-md">
        <motion.img
          src={getPath("/rays.jpg")}
          style={{
            transform: `translate(${(offset.x - 0.5) * 10}%, ${
              (offset.y - 0.5) * 10
            }%) scale(${scale})`
          }}
          className="absolute inset-0 mix-blend-color-dodge object-cover pointer-events-none opacity-30 rounded-md"
        />
        <img
          src={card.imageUrl || ""}
          className="object-cover rounded-md rounded-b-none w-full h-full pointer-events-none border border-black/40"
          alt={card.name}
        />
      </div>
      <div className="relative inset-x-0 bottom-0 bg-[#f4e7d8] border border-t-0 text-center border-black/40 rounded-b-md p-1">
        <Textures type="paper-1" className="opacity-50 rounded-b-md" />
        <span className="relative z-10 font-rosarivo text-xs">{card.name}</span>
      </div>
    </motion.div>
  );
};

const getResponsiveScale = (windowWidth: number) => {
  const { isNative } = getPlatform();
  // if (windowWidth < 768) return 0.95; // md - reduced from 0.95
  if (isNative) return 1; // lg - reduced from 1.1
  if (windowWidth < 1024) return 0.95; // lg - reduced from 1.1
  if (windowWidth < 1280) return 1.05; // xl - reduced from 1.2
  if (windowWidth < 1536) return 1.1; // 2xl - reduced from 1.25
  if (windowWidth < 1800) return 1.15; // custom breakpoint for 1600px screens`
  return 1.2; // larger than 1800px - reduced from 1.3
};

export default DailyDraw;
