import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Module, ModuleProgress } from "../../types/flashcards";
import { flashcardsApi } from "../../utils/api";
import { useAuth } from "../../hooks/useAuth";
import { getPath } from "../../utils/paths";
import Layout from "../layout";
import { Textures } from "../Textures";

const ModuleList: React.FC = () => {
  const [modules, setModules] = useState<Module[]>([]);
  const [progress, setProgress] = useState<ModuleProgress[]>([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const modulesData = await flashcardsApi.getModules();
        setModules(modulesData);

        if (user) {
          const progressData = await flashcardsApi.getUserProgress();
          setProgress(progressData);
        }
      } catch (error) {
        console.error("Error fetching modules:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  // Get progress for a specific module
  const getModuleProgress = (moduleId: number) => {
    return progress.find((p) => p.moduleId === moduleId);
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center h-64 text-primary-foreground">
          Loading modules...
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-8 text-center text-primary-foreground">
          Tarot Learning Path
        </h1>
        <p className="text-lg text-center mb-8 text-primary-foreground mx-auto max-w-2xl">
          Master tarot from beginner to advanced with our structured learning
          modules. Each module builds on the previous one, taking you from basic
          concepts to complex interpretations.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {modules.map((module) => {
            const moduleProgress = getModuleProgress(module.id);
            const percentComplete = moduleProgress?.percentageComplete || 0;

            return (
              <Link
                key={module.id}
                to={getPath(`/learn/study/${module.id}`)}
                className="border border-primary-foreground/20 rounded-lg p-6 hover:shadow-lg transition-shadow bg-primary relative shadow"
              >
                <Textures type="paper-1" />
                <div className="relative z-10 flex flex-col justify-between h-full">
                  <div>
                    <div className="flex justify-between items-start mb-4">
                      <h2 className="text-xl font-semibold text-card-foreground">
                        {module.name}
                      </h2>
                      <span className="text-sm bg-primary/10 text-primary px-2 py-1 rounded">
                        Module {module.order}
                      </span>
                    </div>

                    <p className="text-primary-foreground mb-4">
                      {module.description}
                    </p>
                  </div>

                  {/* Progress bar */}
                  <div className="mt-4">
                    <div className="flex justify-between text-sm mb-1">
                      <span className="text-primary-foreground">Progress</span>
                      <span className="text-primary-foreground">
                        {moduleProgress
                          ? `${moduleProgress.completedFlashcards} of ${moduleProgress.totalFlashcards} cards studied`
                          : "0%"}
                      </span>
                    </div>

                    {/* Loading style progress bar */}
                    <div className="w-full bg-primary-darker border-primary-foreground/20 border rounded-full h-4 overflow-hidden relative mt-4">
                      <div
                        className="bg-primary h-full rounded-full transition-all duration-500 ease-in-out relative"
                        style={{ width: `${percentComplete}%` }}
                      >
                        {percentComplete > 0 && (
                          <div className="absolute inset-0 w-full h-full overflow-hidden">
                            <div className="bg-gradient-to-r from-green-500 via-green-500 to-green-800 h-full w-full" />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default ModuleList;
