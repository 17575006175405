import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useUserStore } from "@/stores/useUserStore";
import { getPath } from "@/utils/paths";
import { getAuthToken } from "@/utils/platform";

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const user = useUserStore((state) => state.user);
  const location = useLocation();
  const [isChecking, setIsChecking] = useState(true);
  const [hasToken, setHasToken] = useState(false);

  // Check for token using the same method as useAuth
  useEffect(() => {
    const checkToken = async () => {
      try {
        const token = await getAuthToken();
        setHasToken(!!token);
      } catch (error) {
        console.error("Error checking token in ProtectedRoute:", error);
      } finally {
        setIsChecking(false);
      }
    };

    checkToken();
  }, []);

  // Show nothing while checking to prevent flash of redirect
  if (isChecking) {
    return null;
  }

  // If no token and no user, redirect to login
  if (!hasToken && !user) {
    console.log("ProtectedRoute - No token or user, redirecting to login");
    return (
      <Navigate to={getPath("/login")} state={{ from: location }} replace />
    );
  }

  // User is authenticated, render children
  return <>{children}</>;
};

export default ProtectedRoute;
