import { useState } from "react";
import { Button } from "@/components/ui/button";
import { resetOnboarding } from "@/utils/platform";
import { useNavigate } from "react-router-dom";
import Navbar from "@/components/navbar";

export default function ResetOnboarding() {
  const navigate = useNavigate();
  const [isResetting, setIsResetting] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);

  const handleReset = async () => {
    setIsResetting(true);
    try {
      await resetOnboarding();
      setResetComplete(true);
      setTimeout(() => {
        // Reload the app to apply changes
        window.location.href = "/";
      }, 2000);
    } catch (error) {
      console.error("Failed to reset onboarding:", error);
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="flex flex-col items-center justify-center p-4 overflow-y-auto"
        style={{
          height: "calc(100vh - 64px)" /* Subtract navbar height */,
          WebkitOverflowScrolling: "touch"
        }}
      >
        <div className="w-full max-w-md p-6 rounded-lg shadow-sm bg-background-light">
          <h1 className="text-2xl font-bold mb-8 text-center">
            Onboarding Reset Utility
          </h1>

          {resetComplete ? (
            <div className="text-center">
              <div className="text-green-600 mb-4">
                Onboarding status reset successfully!
              </div>
              <div>Reloading app...</div>
            </div>
          ) : (
            <>
              <p className="text-center mb-8">
                This utility resets the onboarding status, allowing you to see
                the onboarding screens again.
              </p>

              <div className="flex gap-4 justify-center">
                <Button
                  onClick={handleReset}
                  disabled={isResetting}
                  variant="default"
                >
                  {isResetting ? "Resetting..." : "Reset Onboarding"}
                </Button>

                <Button onClick={() => navigate(-1)} variant="outline">
                  Back
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
