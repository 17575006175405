import { cn } from "@/lib/utils";
import { getAssetPath } from "@/utils/paths";
import { publicUrls } from "@/utils/publicUrls";

interface LogoProps {
  textSize?: "large" | "small";
}

const Logo = ({ textSize = "large" }: LogoProps) => {
  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    window.location.href = publicUrls.home();
  };

  return (
    <a
      href={publicUrls.home()}
      onClick={handleLogoClick}
      className="-m-1.5 p-1.5 flex space-x-4 items-center"
    >
      <div className="relative size-7">
        <img
          src={getAssetPath("/logo.png")}
          alt="Logo"
          className="absolute z-30 inset-0 object-contain size-full"
        />
      </div>
      <span
        className={cn(
          "md:block hidden font-bold relative text-primary-foreground",
          textSize === "large" ? "text-base md:text-2xl" : "text-lg"
        )}
      >
        Your Unique Tarot
      </span>
      <span className="sr-only">Your Unique Tarot</span>
    </a>
  );
};

export default Logo;
