import { Capacitor } from "@capacitor/core";

/**
 * Generates the correct path for routing based on whether the app is running in Capacitor or on the web
 *
 * @param path - The path to navigate to (without /app prefix)
 * @returns The correctly formatted path for the current platform
 *
 * @example
 * // For web: getPath('/login') -> '/login'
 * // For native: getPath('/login') -> '/login'
 */
export const getPath = (path: string): string => {
  // For routing purposes, we don't need to add the base URL
  // since the router's basename is already set to the base URL

  // Ensure path starts with / and prevent double slashes
  const normalizedPath = path.startsWith("/") ? path : `/${path}`;

  // Return the normalized path without adding the base URL
  return normalizedPath;
};

/**
 * Generates the correct path for assets based on whether the app is running in Capacitor or on the web
 *
 * @param path - The path to the asset (without /app prefix)
 * @returns The correctly formatted asset path for the current platform
 *
 * @example
 * // For web: getAssetPath('/logo.png') -> '/app/logo.png'
 * // For native: getAssetPath('/logo.png') -> '/logo.png'
 */
export const getAssetPath = (path: string): string => {
  // Use the same base URL detection logic as getBaseUrl()
  const base = getBaseUrl();

  // Ensure path starts with / and prevent double slashes
  const normalizedPath = path.startsWith("/") ? path : `/${path}`;

  // Join base and path, ensuring no double slashes
  return `${base}${normalizedPath}`.replace(/\/\//g, "/");
};

/**
 * Get the base URL for the router based on platform
 */
export const getBaseUrl = (): string => {
  // ALWAYS use "/" for any Capacitor/mobile context to fix routing issues

  // 1. Check if running in native Capacitor
  if (Capacitor.isNativePlatform()) {
    return "/";
  }

  // 2. Check platform type directly from Capacitor
  const platform = Capacitor.getPlatform();

  if (platform === "ios" || platform === "android") {
    return "/";
  }

  // Note: We're not checking environment variables anymore
  // as they're not reliably passed to the iOS build

  // Default web behavior
  return "/app";
};
